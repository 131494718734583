import * as React from 'react';
import Tabs, { tabsClasses } from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Badge from '@mui/joy/Badge';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import axios from 'axios';
import Config from '../Config';

import ApplicationList from "./ApplicationsList";
import ApplicationList2 from "./ApplicationsList2";
import ApplicationsListFavorite from "./ApplicationsListFavorite";
import ApplicationsListFavorite2 from "./ApplicationsListFavorite2";
import ApplicationsListHolidays from "./ApplicationsListHolidays";
import ApplicationsListHolidays2 from "./ApplicationsListHolidays2";
import ApplicationsListOmluvenka from './ApplicationsListOmluvenka';
import ApplicationsListOmluvenka2 from './ApplicationsListOmluvenka2';

const TAB_CONFIGS = [
  { id: 'vlp', label: 'VLP', component: ApplicationList },
  { id: 'vlp2', label: 'VLP2', component: ApplicationList2 },
  { 
    id: 'favorite-vlp',
    label: 'VLP',
    component: ApplicationsListFavorite,
    icon: FavoriteIcon,
    countKey: 'pendingFavoriteVlp'
  },
  {
    id: 'favorite-vlp2',
    label: 'VLP2',
    component: ApplicationsListFavorite2,
    icon: FavoriteIcon,
    countKey: 'pendingFavoriteVlp2'
  },
  {
    id: 'holidays-vlp',
    label: 'VLP',
    component: ApplicationsListHolidays,
    icon: BeachAccessIcon,
    countKey: 'holidaysVlp'
  },
  {
    id: 'holidays-vlp2',
    label: 'VLP2',
    component: ApplicationsListHolidays2,
    icon: BeachAccessIcon,
    countKey: 'holidaysVlp2'
  },
  {
    id: 'omluvenka-vlp',
    label: 'VLP',
    component: ApplicationsListOmluvenka,
    icon: SentimentVeryDissatisfiedIcon,
    countKey: 'excuseVlp'
  },
  {
    id: 'omluvenka-vlp2',
    label: 'VLP2',
    component: ApplicationsListOmluvenka2,
    icon: SentimentVeryDissatisfiedIcon,
    countKey: 'excuseVlp2'
  }
];


export default function ApplicationsDashboard() {
  const [selectedTabId, setSelectedTabId] = React.useState(TAB_CONFIGS[0].id);
  const [counts, setCounts] = React.useState({
    pendingFavoriteVlp: 0,
    pendingFavoriteVlp2: 0,
    holidaysVlp: 0,
    holidaysVlp2: 0
  });

  React.useEffect(() => {
    const fetchCounts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${Config.domain}/api/officer/info`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setCounts(response.data.counts);
      } catch (error) {
        console.error("Chyba při načítání dat:", error);
      }
    };

    fetchCounts();
  }, []);

  const refreshCounts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${Config.domain}/api/officer/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCounts(response.data.counts);
    } catch (error) {
      console.error("Chyba při aktualizaci dat:", error);
    }
  };
  

  // Získáme pouze ty taby, které mají být viditelné
  const visibleTabs = TAB_CONFIGS.filter(tab => {
    if (!tab.countKey) return true; // Základní taby vždy zobrazujeme
    if (tab.id === 'omluvenka-vlp' || tab.id === 'omluvenka-vlp2') return true; // Omluvenky vždy zobrazujeme
    return counts[tab.countKey] > 0;
  });

  const TabContent = ({ tabId }) => {
    const config = TAB_CONFIGS.find(tab => tab.id === tabId);
    const Component = config?.component;
    return Component ? <Component refreshCounts={refreshCounts} /> : null;
  };

  return (
    <Tabs 
      aria-label="tabs" 
      value={selectedTabId}
      onChange={(event, newValue) => setSelectedTabId(newValue)}
      sx={{ bgcolor: 'transparent' }}
    >
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          position: "sticky",
          top: 0,
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          bgcolor: '#00000025',
          padding: "8px",
          paddingLeft: "10px",
          borderRadius: "6px 6px 0px 0px",
          backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)",
          [`& .${tabClasses.root}`]: {
            boxShadow: 'sm',
            bgcolor: '#00000015',
            borderRadius: "8px"
          },
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: 'sm',
            bgcolor: '#00000060',
            borderRadius: "8px"
          },
          [`& .${tabClasses.root}:hover`]: {
            bgcolor: '#00000025',
            borderRadius: "8px",
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1)',
            color: 'text.primary',
          },
        }}
      >
        {visibleTabs.map((tab, index) => {
          const TabIcon = tab.icon;
          const content = (
            <Tab 
              key={tab.id}
              value={tab.id}
              disableIndicator 
              style={{ marginLeft: index === 0 ? "8px" : "12px" }}
            >
              {TabIcon && <TabIcon sx={{ fontSize: "1.2rem", mr: 0, color: "white" }} />} {tab.label}
            </Tab>
          );

          if (tab.countKey && counts[tab.countKey] > 0) {
            return (
              <Badge
                key={tab.id}
                badgeContent={counts[tab.countKey]}
                color="danger"
                size="md"
                variant="solid"
                sx={{
                  "& .MuiBadge-badge": {
                    boxShadow: "none",
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {content}
              </Badge>
            );
          }

          return content;
        })}
      </TabList>

      {TAB_CONFIGS.map(tab => (
        <TabPanel key={tab.id} value={tab.id}>
          <TabContent tabId={tab.id} />
        </TabPanel>
      ))}
    </Tabs>
  );
}