import { useEffect, useRef } from 'react';

const useLogger = (value) => {
  const previousValue = useRef(value);

  useEffect(() => {
    if (previousValue.current !== value) {
      console.log("State `formData` změněn:", {
        předchozí: previousValue.current,
        nový: value,
      });
      previousValue.current = value;
    }
  }, [value]);
};

export default useLogger;
