import { styled, Input } from '@mui/joy';

const CustomInput = styled(Input, {
  shouldForwardProp: (prop) =>
    prop !== 'error' && prop !== 'disabled' && prop !== 'placeholderColor' && prop !== 'textColor',
})(({ theme, error, disabled, placeholderColor, textColor }) => ({
  backgroundColor: disabled ? '#2a2d2e' : '#1a1d1f40',
  borderRadius: '7px',
  padding: '10px 12px',
  zIndex: "1000",
  fontFamily: "Gemunu Libre",
  fontWeight: "600",
  color: error ? '#ffadad' : textColor || theme.palette.text.primary,

  border: error
    ? '1px solid transparent'
    : '1px solid rgba(255, 255, 255, 0.4)',
  borderImage: error
    ? 'linear-gradient(to right, #ff3b3b, #ff8a3b) 1'
    : 'linear-gradient(to right, rgb(103, 69, 237), rgb(221, 57, 253)) 1',
  borderStyle: 'solid',
  transition: 'border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease',

  '&:hover': {
    borderColor: error ? '#ff3b3b' : 'rgba(255, 255, 255, 0.6)',
  },

  '& input, & textarea': {
    fontSize: '1.1rem',
    color: error ? '#ffadad' : textColor || theme.palette.text.primary,
    background: 'none',

    '&::placeholder': {
      color: error ? '#ffadad' : placeholderColor || 'rgba(255, 255, 255, 0.5)',
      fontSize: '1.1rem',
      opacity: 1,
    },

    '@media (max-width: 600px)': {
      fontSize: '0.9rem',
      '&::placeholder': {
        fontSize: '0.9rem',
      },
    },
  },

  '& input[type=number]': {
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },

  '& textarea': {
    minHeight: '100px',
    resize: 'vertical',
  },

  '&.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#2a2d2e',
    borderColor: 'rgba(255, 255, 255, 0.2)',
  },

  '& .end-decorator': {
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
}));

export default CustomInput;