import React from 'react';
import { Modal, ModalDialog, Grid, Input, Button, Typography, Alert } from '@mui/joy';
import CustomTextarea from './CustomTextarea';
import "../Global.css"
import CustomInput from "./CustomInput"
import { useAlert } from './AlertContext';

export default function SettingModal({ open, onClose, userData, onSave }) {
    const [formData, setFormData] = React.useState({ 
        absenceFrom: '', 
        absenceTo: '', 
        absenceReason: '' 
    });
    const [errors, setErrors] = React.useState({});
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { showAlert } = useAlert();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        // Vymaž chybu pro toto pole při změně
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: null }));
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.absenceFrom) {
            tempErrors.absenceFrom = "Datum od je povinné!";
            showAlert(
                `Datum od kdy je povinné!`,
                'solid',
                'danger'
              );
            isValid = false;
        }

        if (!formData.absenceTo) {
            tempErrors.absenceTo = "Datum do je povinné!";
            showAlert(
                `Datum od kdy je povinné!`,
                'solid',
                'danger'
              );
            isValid = false;
        }

        if (!formData.absenceReason) {
            tempErrors.absenceReason = "Důvod nepřítomnosti je povinný!";
            showAlert(
                `Datum od kdy je povinné!`,
                'solid',
                'danger'
              );
            isValid = false;
        }

        // Kontrola, že datum "do" není dřív než datum "od"
        if (formData.absenceFrom && formData.absenceTo) {
            const startDate = new Date(formData.absenceFrom);
            const endDate = new Date(formData.absenceTo);
            if (endDate < startDate) {
                tempErrors.absenceTo = "Datum konce nemůže být dříve než datum začátku";
                showAlert(
                    `Datum od kdy je povinné!`,
                    'solid',
                    'danger'
                  );
                isValid = false;
            }
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://clan.varmi.cz/api/excuse/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Něco se pokazilo');
            }

            showAlert(
                `Omluvenka byla odeslána!`,
                'solid',
                'success'
              );
            if (onSave) {
                onSave(data.excuse);
            }
            onClose();
        } catch (error) {
            console.error('Chyba při odesílání:', error);
            showAlert(
                `Něco se dojebalo!!`,
                'solid',
                'danger'
              );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose} style={{ zIndex: "600", padding: "20px" }}>
            <ModalDialog sx={{ width: "600px", borderRadius: "12px", p: 6 }}>
                <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: "15px" }}>
                    Nahlásit Nepřítomnost
                </Typography>
                
                {Object.keys(errors).map((key) => (
                    errors[key] && (
                        <Alert 
                            color="danger" 
                            key={key} 
                            style={{ marginBottom: "5px", borderRadius: "7px" }}
                        >
                            {errors[key]}
                        </Alert>
                    )
                ))}
                
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomInput
                            type="date"
                            label="Nepřítomnost od"
                            name="absenceFrom"
                            placeholder="Nepřítomnost od"
                            value={formData.absenceFrom}
                            onChange={handleInputChange}
                            error={!!errors.absenceFrom}
                            placeholderColor="rgb(230, 181, 255)"
                            textColor="#bdadff"
                            style={{ marginBottom: "15px" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            type="date"
                            label="Nepřítomnost do"
                            name="absenceTo"
                            value={formData.absenceTo}
                            onChange={handleInputChange}
                            error={!!errors.absenceTo}
                            placeholderColor="rgb(230, 181, 255)"
                            textColor="#bdadff"
                            style={{ marginBottom: "15px" }}
                        />
                    </Grid>
                </Grid>
                
                <CustomTextarea
                    placeholder="Důvod nepřítomnosti"
                    name="absenceReason"
                    value={formData.absenceReason}
                    onChange={handleInputChange}
                    error={!!errors.absenceReason}
                    rows={3}
                    style={{ width: "100%", marginBottom: "15px" }}
                    placeholderColor="#bdadff"
                    textColor="#bdadff"
                />

                <Button
                    style={{ 
                        borderRadius: "7px", 
                        backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)",
                        opacity: isSubmitting ? 0.7 : 1
                    }}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Odesílám...' : 'Odeslat'}
                </Button>
            </ModalDialog>
        </Modal>
    );
}