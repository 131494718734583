import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  Card,
  Box,
  AspectRatio,
  CardContent,
  extendTheme,
  CssVarsProvider,
  Input,
  styled,
  Alert,
  Checkbox,
  Typography,
  Sheet,
  FormLabel,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  List,
  ListItem,
  CircularProgress,
  ListItemDecorator,
  Textarea,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack
} from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { FormControlLabel, FormGroup } from "@mui/material";
import Config from "../Config";
import StepButton from '@mui/joy/StepButton';
import StepIndicator from '@mui/joy/StepIndicator';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import Check from '@mui/icons-material/Check';
import axios from 'axios';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import PersonIcon from '@mui/icons-material/Person';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DiscordLoginButton } from "react-social-login-buttons";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileUpload from './FileUpload'; // Import FileUpload komponenty
import { useAlert } from './AlertContext';
import CustomInput from "./CustomInput"
import useLogger from './Logger';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomTextarea from "./CustomTextarea"

import autoFarmImage from '../icons/auto_farm.webp';
import autoTapImage from '../icons/auto_tap.webp';
import hugeHunterImage from '../icons/huge_hunter.webp';
import luckyImage from '../icons/lucky.webp';
import magicEggsImage from '../icons/magic_eggs.webp';
import moreEggsImage from '../icons/more_eggs.webp';
import petEquipImage from '../icons/pet_equip.webp';
import ultraLuckyImage from '../icons/ultra_lucky.webp';
import vipImage from '../icons/vip.webp';
import enchantImage from '../icons/enchant.webp';
import dayCareImage from "../icons/dayCare.webp";
import superDropImage from "../icons/superDrop.webp"
import doubleStarsImage from "../icons/double_star.webp"



const steps = ['Discord', 'Roblox', 'Informace', 'Inventář'];

function formatNumber(num, isCWPoints = false) {
  const sign = num < 0 ? '-' : '';
  const absNum = Math.abs(num);

  const formatWithOptionalDecimal = (value, divider, unit) => {
    const dividedValue = value / divider;
    let formattedNumber;
    if (Math.floor(dividedValue) !== dividedValue) {
      formattedNumber = dividedValue.toFixed(2);
    } else {
      formattedNumber = dividedValue.toFixed(0);
    }
    return sign + formattedNumber + unit;
  };

  if (isCWPoints) {
    if (absNum < 1000) return sign + absNum.toFixed(0);
    if (absNum < 1000000) return sign + (absNum / 1000).toFixed(2) + 'k';
    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
  } else {
    if (absNum < 1000) return sign + absNum.toFixed(0);
    if (absNum < 1000000) return sign + (absNum / 1000).toFixed(0) + 'k';
    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
    return formatWithOptionalDecimal(absNum, 1000000000000, 'T');
  }

  return sign + absNum; // Pro jiné případy
}

function formatDate(isoDate) {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // měsíce jsou indexované od 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}. ${month}. ${year} ${hours}:${minutes}`;
}

function RegisterForm({ open, onClose, onRegistrationSuccess }) {

  open = true
  const [formData, setFormData] = useState({
    nick: "",
    discord: "",
    password: "",
    confirmPassword: "",
    isMember: false, //Je členem varmi discordu
    playTime: "", //Kolik hodin denně hraje roblox
    startFee: "", //Vstupní poplatek
    note: "", //Poznámka
    huges: "", //Počet huge petů
    titanics: "", //Počet titaniků
    clan: "vlp", //Clan
    gamepasses: { //herní gamepassy
      lucky: false,
      ultraLucky: false,
      hugeHunter: false,
      magicEggs: false,
      vip: false,
      autofarm: false,
      autotap: false,
      petEquip: false,
      moreEggs: false,
      superDrops: false,
      daycare: false,
      doubleStars: false
    },
    discordId: "",
    displayName: "", //Roblox display name
    robloxId: "", 
    avatar: "", //profilový obrázek roblox
    locale: "", //země
    rbxPremium: false, //Roblox premium
    createTime: "", //datum založení roblox účtu
    screens: [], //nahrané obrázky
    messageCount: 0, //počet zpráv na discordu
    voiceTime: "", //čas strávený ve voice
    sub: false, //Kick Sub
    booster: false, //Discord server booster
    rank: "", //PetSim rank
    penalty: 0, //Počet trestů na discordu
    giftedSubs: 0, //Giftnuté suby
    disToken: "",
    disLevel: "", //Level na discordu
    refreshToken: "",
    tokenExpire: ""
  });

  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [step, setStep] = React.useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [discordVerified, setDiscordVerified] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [discordId, setDiscordId] = useState()
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [value, setValue] = useState(null);
  const [startFee, setStartFee] = useState("");
  const [playTime, setPlayTime] = useState("");
  const [huges, setHuges] = useState("");
  const [titanics, setTitanics] = useState("");
  const [layout, setLayout] = useState(undefined);
  const [selectedClan, setSelectedClan] = useState('vlp');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    clan: '',
    startFee: '',
    playTime: '',
    password: '',
    note: "",
    huges: "",
    titanics: "",
    rank: "",
    confirmPassword: ''
  });
  const { showAlert } = useAlert();

  useLogger(formData);


  useEffect(() => {
    const isMobile = window.innerWidth <= 600;
    setLayout(isMobile ? 'fullscreen' : 'center');
    console.log(layout)
  }, [layout]);


  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const renderGamePasses = (gamepasses) => {

    const gamePassImages = {
      autofarm: autoFarmImage,
      autotap: autoTapImage,
      hugeHunter: hugeHunterImage,
      lucky: luckyImage,
      magicEggs: magicEggsImage,
      moreEggs: moreEggsImage,
      petEquip: petEquipImage,
      ultraLucky: ultraLuckyImage,
      vip: vipImage,
      daycare: dayCareImage,
      superDrops: superDropImage,
      doubleStars: doubleStarsImage,
    };
    const gamePassElements = Object.entries(gamepasses).map(([key, value]) => {
      if (value === true) {
        return (
          <Tooltip key={key} title={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}>
            <img src={gamePassImages[key]} alt={key} style={{ width: '25px', height: '25px', paddingTop: "9px", paddingLeft: "10px"}} />
          </Tooltip>
        );
      }
      return null;
    });

  return gamePassElements; // Toto je správně umístěný return pro celou funkci
};

  const getDiscordStats = async (discordId, discordUsername) => {
    try {
      const response = await fetch(`https://clan.varmi.cz/v2/api/DiscordStats/${discordId}`);

      if (!response.ok) {
        throw new Error(`Chyba: ${response.status}`);
      }

      const data = await response.json();

      return data; // Vrátíme data pro další zpracování
    } catch (error) {
      console.error('Chyba při volání endpointu:', error);
      return null;
    }
  }


  const handleNext = async () => {
    try {
      const discordId = localStorage.getItem('discord_id');
      const discordUsername = localStorage.getItem('discord_username');
      const isMember = localStorage.getItem('isMember') === 'true';
      const tokenExpire = localStorage.getItem('tokenExpire');
      const disToken = localStorage.getItem('disToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const isSub = localStorage.getItem('isSub') === 'true';
      const isBooster = localStorage.getItem('isBooster') === 'true';

      // Nejdřív zkontrolujeme členství
      if (!isMember) {
        Alert(
          `Pro odeslání přihlášky musíš být členem Varmiho discordu! Poté stačí kliknout na tlačítko "Přihlásit se přes Discord". Discord: https://discord.gg/varmi-army-656165397518876692`,
          'solid',
          'danger'
        );
        return;
      }

      const discordStats = await getDiscordStats(discordId, discordUsername);

      if (!discordStats) {
        setErrors('Nepodařilo se získat Discord statistiky');
        return;
      }


      await new Promise(resolve => {
        setFormData(prevFormData => ({
          ...prevFormData,
          discordId: discordId,
          discord: discordUsername,
          tokenExpire: tokenExpire,
          disToken: disToken,
          tokenExpire: tokenExpire,
          refreshToken: refreshToken,
          messageCount: discordStats.messageCount,
          voiceTime: discordStats.voiceTime,
          disLevel: discordStats.level,
          penalty: discordStats.penalty,
          giftedSubs: discordStats.giftedSubs,
          sub: isSub,
          booster: isBooster 

        }));
        resolve();
      });

      // Kontrola ostatních podmínek až po ověření členství
      if (discordId && discordUsername) {
        setActiveStep(prevStep => prevStep + 1);
      } else {
        setErrors('Něco se pokazilo, zkus se znovu přihlásit přes discord!');
      }
    } catch (error) {
      console.error('Chyba při zpracování:', error);
      setErrors('Došlo k chybě při zpracování dat');
    }
  };


  const handleNextRoblox = async () => {
    if (formData.discordId && formData.discord) {
      setActiveStep(prevStep => prevStep + 1); // Pokračování na další krok
    };
  }

  const localeToFlag = (Locale) => {
    if (Locale === "cs_cz") {
      return "🇨🇿"
    } else if (Locale === "sk_sk") {
      return "🇸🇰"
    } else {
      return ""
    }
  }


  const handleNextInfo = async () => {
    const ifIsValid = validateForm()
    console.log(ifIsValid)
    if (ifIsValid === true) {
      setActiveStep(prevStep => prevStep + 1); // Pokračování na další krok
    };
  }



  function RobloxCard({ Avatar, UserName, Locale, DisplayName, CreateDate, RobloxId }) {
    return (
      <Card
        orientation="horizontal"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          [`& > *`]: {
            '--stack-point': '500px',
            minWidth:
              'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
          },
          overflow: 'auto',
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
          <img
            src={Avatar}
            srcSet={Avatar}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
        <CardContent>
          <Typography sx={{ fontSize: 'xl', fontWeight: 'lg' }}>
            {UserName} {localeToFlag(Locale)}
          </Typography>
          <Typography
            level="body-sm"
            textColor="text.tertiary"
            sx={{ fontWeight: 'lg' }}
          >
            {DisplayName}
          </Typography>
          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" sx={{ fontWeight: 'lg' }}>
                Datum registrace:
              </Typography>
              <Typography sx={{ fontWeight: 'lg' }}>{formatDate(CreateDate)}</Typography>
            </div>
            <div>
              <Typography level="body-xs" sx={{ fontWeight: 'lg' }}>
                RobloxId:
              </Typography>
              <Typography sx={{ fontWeight: 'lg' }}>{RobloxId}</Typography>
            </div>
          </Sheet>
          <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
            <Button onClick={handleNextRoblox} style={{ background: 'linear-gradient(to right, #6745ed, #DD39FD)', color: 'white' }}>
              <strong>Je to můj účet, pokračovat!</strong>
            </Button>
          </Box>
        </CardContent>
      </Card>

    );
  }


  // Funkce pro změnu hodnot ve formuláři
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    const errors = {};
  
    // Kontrola prázdných polí
    if (!formData.startFee) errors.startFee = 'Start Fee je povinné!';
    if (!formData.playTime) errors.playTime = 'Play time je povinný!';
    if (!formData.password) errors.password = 'Heslo je povinné!';
    if (!formData.confirmPassword) errors.confirmPassword = 'Potvrzení hesla je povinné!';
    if (!formData.note) errors.note = 'Poznámka je povinná!';
    if (!formData.huges) errors.huges = 'Počet huges je povinný!';
    if (!formData.titanics) errors.titanics = 'Počet titaniků je povinný!';
    if (!formData.rank) errors.rank = 'Rank je povinný!';
  
    // Validace hesla
    if (formData.password && formData.password.length < 6) {
      errors.password = 'Heslo musí mít alespoň 6 znaků!';
    }
  
    // Kontrola shody hesel
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Hesla se neshodují!';
    }
  
    // Validace playTime (číslo od 1 do 24)
    if (!/^[1-9]$|^1[0-9]$|^2[0-4]$/.test(formData.playTime.toString())) {
      errors.playTime = 'Play time musí být číslo od 1 do 24!';
    }
  
    // Validace startFee
    if (
      !/^(0|(\d+(\.\d+)?[a-zA-Z]{1}))$/.test(formData.startFee.toString()) ||
      formData.startFee.toString().replace(/[^a-zA-Z]/g, "").length > 1
    ) {
      errors.startFee = 'Špatný formát Start Fee!';
    }
  
    // Pokud jsou chyby, zobraz je
    if (Object.keys(errors).length > 0) {
      for (const field in errors) {
        showAlert(errors[field], 'solid', 'danger');
      }
      setValidationErrors(errors);
      return false; // Validation failed
    }
  
    // Žádné chyby - validace prošla
    setValidationErrors({});

    return true; // Validation passed
  };
  


  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    console.log("STEP 2 START:", formData)

    try {
      const response = await fetch(`${Config.domain}/api/roblox/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: userName }) // posílá username jako JSON v body
      });

      if (!response.ok) {
        const errorData = await response.json(); // Předpokládám, že endpoint vrací JSON s chybovou zprávou
        throw new Error(errorData.error || 'Neznámá chyba'); // Zobrazí zprávu z endpointu nebo náhradní text
      }

      const data = await response.json();
      setFormData((prevFormData) => ({
        ...prevFormData,
        nick: data.userName,
        displayName: data.displayName,
        robloxId: data.robloxId,
        avatar: data.avatar,
        locale: localeToFlag(data.locale),
        rbxPremium: data.premium,
        createTime: data.createTime,
        gamepasses: {
          ...prevFormData.gamepasses,
          ...data.gamepasses
        }
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
    console.log("STEP 2 END:", formData)

  };



  const sendToServer = async (data) => {
    try {
      const response = await fetch(`${Config.domain}/api/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nick: formData.nick,
          discord: formData.discord,
          password: formData.password,
          enchantSlots: formData.enchantSlots,
          playTime: formData.playTime,
          startFee: data.startFee,
          gamepasses: formData.gamepasses,
          isMember: formData.isMember,
          note: formData.note,
          dmgPet: formData.dmgPet,
          huges: formData.huges,
          titanics: formData.titanics,
          clan: formData.clan,
          discordId: formData.discordId,
          displayName: formData.displayName,
          robloxId: formData.robloxId,
          avatar: formData.avatar,
          locale: formData.locale,
          rbxPremium: formData.rbxPremium,
          createTime: formData.createTime,
          screens: formData.screens,
          messageCount: formData.messageCount,
          voiceTime: formData.voiceTime,
          sub: formData.sub, //Kick Sub
          booster: formData.booster, //Discord server booster
          rank: formData.rank, //PetSim rank
          penalty: formData.penalty, //Počet trestů na discordu
          giftedSubs: formData.penalty, //Giftnuté suby
          disToken: formData.disToken,
          disLevel: formData.disLevel, //Level na discordu
          refreshToken: formData.refreshToken,
          tokenExpire: formData.tokenExpire
        }),
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(
          result.message || `HTTP error! status: ${response.status}`
        );
      }
      onRegistrationSuccess(true);
      console.log(result);
    } catch (e) {
      console.error("Chyba při odesílání dat: ", e.message);
      setServerError(e.message);
    }
  };

  const handleRegistrationComplete = (data) => {
    setIsSubmitted(true)
    setIsRegisterModalOpen(false); // Zavře původní modal se Stepperem
    setIsSummaryOpen(true); // Otevře nový modal s rekapitulací
    handleSubmit()
    console.log('Kompletní data formuláře:', data);

  };


  const handleSubmit = () => {
    if (validateForm()) {
      sendToServer({
        nick: formData.nick,
        discord: formData.discord,
        password: formData.password,
        enchantSlots: formData.enchantSlots,
        playTime: formData.playTime,
        startFee: formData.startFee,
        gamepasses: formData.gamepasses,
        isMember: formData.isMember,
        note: formData.note,
        dmgPet: formData.dmgPet,
        huges: formData.huges,
        titanics: formData.titanics,
        discordId: formData.discordId,
        robloxId: formData.robloxId,
        avatar: formData.avatar,
        locale: formData.locale,
        rbxPremium: formData.rbxPremium,
        createTime: formData.createTime,
        screens: [formData.screens],
        messageCount: formData.messageCount,
          voiceTime: formData.voiceTime,
          sub: formData.sub, //Kick Sub
          booster: formData.booster, //Discord server booster
          rank: formData.rank, //PetSim rank
          penalty: formData.penalty, //Počet trestů na discordu
          giftedSubs: formData.penalty, //Giftnuté suby
          disToken: formData.disToken,
          disLevel: formData.disLevel, //Level na discordu
          refreshToken: formData.refreshToken,
          tokenExpire: formData.tokenExpire,
      });
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.name === "isMember") {
      setFormData({ ...formData, isMember: event.target.checked });
    } else {
      setFormData({
        ...formData,
        gamepasses: {
          ...formData.gamepasses,
          [event.target.name]: event.target.checked,
        },
      });
    }
  };

  const formatLabel = (label) => {
    return label
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleClanChange = (event) => {
    setSelectedClan(event.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      clan: event.target.value
    }));
  };
  

  const radioOptions = [
    { value: "vlp", label: "Main Clan (VLP)" },
    { value: "vlp2", label: "Alt Clan (VLP2)" }
  ];

  const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px', 
  }));

  const CustomListItem = styled(ListItem)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1a1d1f40',
    borderRadius: '7px',
    border: '1px solid transparent',
    borderImage: 'linear-gradient(to right, rgb(103, 69, 237), rgb(221, 57, 253)) 1',
    transition: 'border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease',
    height: '44px',
    marginRight: '15px', // mezera mezi položkami

    '&:last-of-type': {
      marginRight: 0, // poslední položka bez mezery vpravo
    },

    '&:hover': {
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
  }));

  const CustomRadio = styled(Radio)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgb(230, 181, 255)',
    fontFamily: '"Gemunu Libre", serif',
    fontWeight: 600,
    fontSize: '1rem',

    '& .Mui-checked .MuiSvgIcon-root': {
      color: '#dd39fd', // puntík bez borderImage, čistě růžový
    },
  }));

  const radioGroup = (
    <CustomRadioGroup
      aria-label="clan type"
      name="clanType"
      defaultValue="vlp"
      value={formData.clan}
      sx={{mb:2}}
      onChange={handleClanChange}
    >
      <List sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
        {radioOptions.map((option) => (
          <CustomListItem key={option.value}>
            <CustomRadio overlay name="clan" value={option.value} label={option.label} />
          </CustomListItem>
        ))}
      </List>
    </CustomRadioGroup>
  );



  const theme = extendTheme({ cssVarPrefix: "demo" });
  return (
    <>

<Modal open={isSummaryOpen} onClose={() => { setIsSummaryOpen(false); onClose(); }} style={{ zIndex: '500', height: '100vh', overflow: 'auto' }}>
<ModalDialog
          layout={layout}
          sx={{
            width: '700px',
            height: 'max-content',
            borderRadius: '12px',
            padding: '40px',
            overflow: 'auto',
            '@media (max-width:600px)': {
              height: 'max-content',
              overflow: 'auto',
              padding: "10px",
              margin: "0px",
              maxWidth: "100%"
            },
          }}
        > 
        <div style={{ padding: '20px' }}>
        <Alert
                            variant="soft"
                            color="success"
                            sx={{ borderRadius: "12px", width: "95%", mt: "15px" }}
                            startDecorator={<CheckCircleOutlineIcon sx={{ fontSize: "3rem", color: "#C7F7C7" }} />}
                        >
                            <div>
                                <div><strong><Typography sx={{ color: "#fff" }}>Přihláška odeslána!</Typography></strong></div>
                                <Typography level="body-sm" color="#C7F7C7" sx={{ color: "#C7F7C7" }}>Nyní se můžeš přihlásit, níže najdeš přehled informací v přihlášce! </Typography></div>
                        </Alert>
          <Typography variant="h6" style={{ marginTop: '15px' }}>Rekapitulace údajů:</Typography>

          <Card className="w-full">

   
      <CardContent>
        <div className="grid grid-cols-3 gap-4">
        <div>
            <p><strong>Nick:</strong> {formData.nick}</p>
            <p><strong>Discord:</strong> {formData.discord}</p>
            <p><strong>Člen discordu:</strong> {formData.isMember ? 'Ano' : 'Ne'}</p>
            <p><strong>Denní hrací čas:</strong> {formData.playTime}</p>
            <p><strong>Vstupní poplatek:</strong> {formData.startFee}</p>
            <p><strong>Poznámka:</strong> {formData.note}</p>
            <p><strong>Počet Huge petů:</strong> {formData.huges}</p>
            <p><strong>Počet Titaniků:</strong> {formData.titanics}</p>
            <p><strong>Vybraný clan:</strong> {formData.clan}</p>
          </div>
          <div>
            <p><strong>GamePasses</strong></p>
            {renderGamePasses(formData.gamepasses)}
          </div>
          <div>
            <p><strong>Discord ID:</strong> {formData.discordId}</p>
            <p><strong>Roblox DisplayName:</strong> {formData.displayName}</p>
            <p><strong>Roblox ID:</strong> {formData.robloxId}</p>
            <p><strong>Roblox avatar:</strong> {formData.avatar}</p>
            <p><strong>Lokalita:</strong> {formData.locale}</p>
            <p><strong>Roblox premium:</strong> {formData.rbxPremium ? 'Ano' : 'Ne'}</p>
            <p><strong>Datum vytvoření Roblox účtu:</strong> {formData.createTime}</p>
            <p><strong>Nahraných obrázků:</strong> {formData.screens.length}</p>
            <p><strong>Počet zpráv na Discordu:</strong> {formData.messageCount}</p>
            <p><strong>Čas strávený ve voice na Discordu:</strong> {formData.voiceTime}</p>
            <p><strong>Kick Sub:</strong> {formData.sub ? 'Ano' : 'Ne'}</p>
            <p><strong>Discord server booster:</strong> {formData.booster ? 'Ano' : 'Ne'}</p>
            <p><strong>PetSim rank:</strong> {formData.rank}</p>
            <p><strong>Počet trestů na Discordu:</strong> {formData.penalty}</p>
            <p><strong>Giftnuté suby:</strong> {formData.giftedSubs}</p>
            <p><strong>Discord level:</strong> {formData.disLevel}</p>
          </div>
          </div>
      </CardContent>
    </Card>

    <Button onClick={() => {
                            setIsSummaryOpen(false);
                            onClose();
                        }} style={{ marginTop: '15px' }}>
                            Zavřít
                        </Button>
        </div>
        </ModalDialog>
      </Modal>


      <Modal open={open} onClose={onClose} style={{ zIndex: '500', height: '100vh', overflow: 'auto' }}>
        <ModalDialog
          layout={layout}
          sx={{
            width: '700px',
            height: 'max-content',
            borderRadius: '12px',
            padding: '40px',
            overflow: 'auto',
            '@media (max-width:600px)': {
              height: 'max-content',
              overflow: 'auto',
              padding: "10px",
              margin: "0px",
              maxWidth: "100%"
            },
          }}
        >          <Stepper sx={{ pt: "15px", width: '100%', color: 'white' }}>
            {steps.map((step, index) => (
              <Step
                orientation="vertical"
                key={step}
                indicator={
                  <StepIndicator variant={activeStep <= index ? 'soft' : 'solid'} color={activeStep < index ? 'neutral' : 'primary'}>
                    {activeStep <= index ? index + 1 : <Check />}
                  </StepIndicator>
                }
                sx={[
                  activeStep > index &&
                  index !== 3 && { '&::after': { bgcolor: '#DD39FD' } },
                ]}
              >

                <StepButton style={{ color: 'white' }} 
                //onClick={() => setActiveStep(index)}
                >
                  {step}
                </StepButton>


              </Step>
            ))}
          </Stepper>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start" // změna z "center" na "flex-start"
            sx={{
              pt: "35px",
              minHeight: '85vh',
              '@media (max-width:600px)': {
                minHeight: '100vh',
              },
            }}
          >
            <Grid item xs={12}>
              {activeStep === 0 && (
                <div >
                  {discordVerified ? (
                    <>

                      <Input
                        label="Discord Username"
                        variant="outlined"
                        value={formData.discordUser?.username || ''}
                        disabled
                      />
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button onClick={handleNext} style={{ background: 'linear-gradient(to right, #6745ed, #DD39FD)', color: 'white' }} fullWidth>
                          Další krok
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div sx={{ mb: "100px" }}>
                        <Alert
                          variant="soft"
                          color="myColor"
                          sx={{ borderRadius: "12px", width: "95%", mb: "25px" }}
                          startDecorator={<InfoOutlinedIcon sx={{ fontSize: "3rem", color: "#b5e8ff" }} />}
                        >
                          <div>
                            <div><strong><Typography sx={{ color: "#fff" }}>Informace</Typography></strong></div>
                            <Typography level="body-sm" color="#b5e8ff" sx={{ color: "#b5e8ff" }}>
                              <p>1. Před odesláním přihlášky si ověř, že jsi na Varmiho discordu (<a href="https://discord.gg/varmi-army-656165397518876692" target="_blank">POZVÁNKA ZDE</a>). Také si zkontroluj, že ti mohou psát i lidé, které nemáš v přátelích, ať se ti můžeme případně ozvat.</p>
                              <p>2. Pokračuj kliknutím na tlačítko níže "Příhlásit se přes Discord", tím dojde k ověření, že jsi na Varmiho discordu a zároveň se ti spáruje discord účet s webem.</p>
                            </Typography>
                          </div>
                        </Alert>
                        <DiscordLoginButton onClick={() => window.open(`https://discord.com/oauth2/authorize?client_id=1232991217856544768&response_type=code&redirect_uri=https%3A%2F%2Fclan.varmi.cz%2Fapi%2Fdiscord%2Fcallback&scope=identify+guilds.members.read`, '_blank')}>
                          <span><strong>Přihlásit se přes Discord</strong></span>
                        </DiscordLoginButton>
                      </div>
                    </>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                    <Button onClick={handleNext} style={{ background: 'linear-gradient(to right, #6745ed, #DD39FD)', color: 'white', width: "100%"}} fullWidth>
                      Další krok
                    </Button>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <Grid container spacing={2}>
                  <Stack flexGrow={1} spacing={1}>
                    <FormLabel style={{ paddingLeft: '2px' }}>Zadej tvůj roblox UserName:</FormLabel>
                    <CustomInput
                      fullWidth
                      size="xl"
                      variant="soft"
                      value={userName}
                      placeholderColor="rgb(230, 181, 255)"
                      textColor="#bdadff"
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ borderRadius: '6px', width: "100%", marginBottom: "20px" }}
                      startDecorator={<PersonIcon style={{ fontSize: 30, paddingLeft: 8, color: "#DD39FD" }} />}
                      endDecorator={
                        <Button
                          style={{
                            backgroundImage: 'linear-gradient(to right, #6745ed, #DD39FD)',
                            borderRadius: '0px 7px 7px 0px',
                            color: 'white',
                            height: "100%"
                          }}
                          onClick={handleSearch}
                          disabled={loading}
                        >
                          {loading ? <CircularProgress color="neutral" variant="soft" /> : 'Vyhledat'}
                        </Button>
                      }
                      placeholder={'Roblox UserName'}
                    />

                  </Stack>
                  {error && <Alert
                    variant="soft"
                    color="warning"
                    sx={{ borderRadius: "12px", width: "95%", mt: "15px" }}
                    startDecorator={<ErrorOutlineOutlinedIcon sx={{ fontSize: "3rem", color: "#E89822" }} />}
                  >
                    <div>
                      <div><strong><Typography sx={{ color: "#fff" }}>Upozornění</Typography></strong></div>
                      <Typography level="body-sm" color="#fff8b5" sx={{ color: "#fff8b5" }}>{error} [<a href="https://www.roblox.com/my/account#!/privacy" target="_blank">OTEVŘÍT ROBLOX NASTAVENÍ</a>]</Typography></div>
                  </Alert>}
                  {formData.robloxId && (
                    <RobloxCard
                      Avatar={formData.avatar}
                      UserName={formData.nick}
                      Locale={formData.locale}
                      DisplayName={formData.displayName}
                      CreateDate={formData.createTime}
                      RobloxId={formData.robloxId}
                    />
                  )}
                  {errors.roblox && <div>{errors.roblox}</div>}
                </Grid>
              )}

              {activeStep === 2 && (
                <form onSubmit={handleSubmit}>
                  {(validationError || serverError) && (
                    <Alert
                      color="danger"
                      variant="solid"
                      severity="error"
                      style={{
                        marginBottom: '15px',
                        border: '1px solid red',
                        borderRadius: '8px',
                      }}
                    >
                      <strong>{validationError || serverError}</strong>
                    </Alert>
                  )}
                  
                    <Grid item xs={12}>
                      {radioGroup}
                    </Grid>
                    {/*
                    <Grid item xs={6}>
                      <CustomInput
                        endDecorator={<LockOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}
                        placeholder="Roblox nick"
                        variant="soft"
                        name="nick"
                        value={formData.nick}
                        onChange={handleChange}
                        error={!!validationErrors.nick}
                        placeholderColor="#686868"
                        textColor="#686868"
                        className="disabledButton"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        endDecorator={<LockOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}
                        placeholder="Discord"
                        variant="soft"
                        name="discord"
                        value={formData.discord}
                        onChange={handleChange}
                        error={!!validationErrors.discord}
                        placeholderColor="#686868"
                        textColor="#686868"
                        className="disabledButton"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                        disabled={true}
                      />
                    </Grid>
                    */}
                  

                  <CustomInput
                    endDecorator={<DiamondOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}
                    placeholder="Ihned po přijetí vložím do clanu (např. 100k, 2m, 1.2m..)"
                    variant="soft"
                    name="startFee"
                    value={formData.startFee}
                    onChange={handleChange}
                    error={!!validationErrors.startFee}
                    placeholderColor="rgb(230, 181, 255)"
                    textColor="#bdadff"
                    style={{ marginBottom: "25px", borderRadius: "8px" }}
                  />

                  <CustomInput
                    endDecorator={<AccessTimeOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}
                    placeholder="Kolik hodin denně máš spuštěný roblox?"
                    variant="soft"
                    name="playTime"
                    value={formData.playTime}
                    onChange={handleChange}
                    error={!!validationErrors.playTime}
                    placeholderColor="rgb(230, 181, 255)"
                    textColor="#bdadff"
                    type="number"
                    style={{ marginBottom: "25px", borderRadius: "8px" }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomInput
                       endDecorator={<KeyOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}                      
                       type="password"
                        placeholder="Vytvořit heslo"
                        variant="soft"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!validationErrors.password}
                        placeholderColor="rgb(230, 181, 255)"
                        textColor="#bdadff"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        endDecorator={<KeyOutlinedIcon sx={{fontSize: "1.4rem", color: "#DD39FD"}}/>}                                           
                        type="password"
                        placeholder="Potvrdit heslo"
                        variant="soft"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={!!validationErrors.confirmPassword}
                        placeholderColor="rgb(230, 181, 255)"
                        textColor="#bdadff"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                      />
                    </Grid>
                  </Grid>
                  <CustomTextarea
                    placeholder="Něco o sobě, CW body, úspěchy, atd."
                    variant="soft"
                    name="note"
                    value={formData.note}
                    onChange={handleChange}
                    error={!!validationErrors.note}
                    fullWidth
                    placeholderColor="rgb(230, 181, 255)"
                    textColor="#bdadff"
                    style={{ marginBottom: "25px", borderRadius: "8px" }}
                  />
                        
                  <Typography component="h6" style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "10px", fontFamily: "Gemunu Libre" }}>
                    Tvůj inventář a rank:
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <CustomInput
                        endDecorator={<PetsOutlinedIcon sx={{fontSize: "1.3rem", color: "#DD39FD"}}/>}                                                         
                        placeholder="Počet huges"
                        variant="soft"
                        name="huges"
                        value={formData.huges}
                        onChange={handleChange}
                        error={!!validationErrors.huges}
                        type="number"
                        placeholderColor="rgb(230, 181, 255)"
                        textColor="#bdadff"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomInput
                        endDecorator={<PetsOutlinedIcon sx={{fontSize: "1.5rem", color: "#DD39FD"}}/>}                                                         
                        placeholder="Počet titaniků"
                        variant="soft"
                        name="titanics"
                        value={formData.titanics}
                        onChange={handleChange}
                        error={!!validationErrors.titanics}
                        type="number"
                        placeholderColor="rgb(230, 181, 255)"
                        textColor="#bdadff"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomInput
                        endDecorator={<MilitaryTechOutlinedIcon sx={{fontSize: "1.5rem", color: "#DD39FD"}}/>}                                                         
                        placeholder="Rank v PS99"
                        variant="soft"
                        name="rank"
                        value={formData.rank}
                        onChange={handleChange}
                        error={!!validationErrors.rank}
                        type="number"
                        placeholderColor="rgb(230, 181, 255)"
                        textColor="#bdadff"
                        style={{ marginBottom: "25px", borderRadius: "8px" }}
                      />
                    </Grid>
                  </Grid>
                  <Typography component="h6" style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "10px", fontFamily: "Gemunu Libre", paddingTop: "0px", marginTop: "0px" }}>
                    Tvoje gamepassy:
                  </Typography>
                  <div style={{ marginBottom: "25px", border: '1px solid rgba(255, 255, 255, 0.4)', borderImage: 'linear-gradient(to right, rgb(103, 69, 237), rgb(221, 57, 253)) 1', borderStyle: 'solid', transition: 'border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease', backgroundColor: "#1a1d1f40", height: "44px" }}>{renderGamePasses(formData.gamepasses)}</div>

              
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button onClick={handleNextInfo} style={{ background: 'linear-gradient(to right, #6745ed, #DD39FD)', color: 'white' }}>
                      Další krok
                    </Button>
                  </div>
                </form>
              )}

              {activeStep === 3 && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                  <FileUpload
                    formData={formData}
                    setFormData={setFormData}
                    onRegistrationComplete={handleRegistrationComplete}
                  />
                </div>
              )}
            </Grid>
          </Grid>
            
        </ModalDialog>
      </Modal>
    </>
  );
};

export default RegisterForm