import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Sheet,
  Tooltip,
  Modal,
  ModalDialog,
  Input,
  Select,
  Option,
  Stack,
  FormLabel,
  Chip,
  Grid
} from '@mui/joy';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Config from '../Config';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Clipboard from 'react-clipboard'
import CustomTextarea from "./CustomTextarea"
import axios from "axios"
import { useAlert } from './AlertContext';


//obrázky
import autoFarmImage from '../icons/auto_farm.webp';
import autoTapImage from '../icons/auto_tap.webp';
import hugeHunterImage from '../icons/huge_hunter.webp';
import luckyImage from '../icons/lucky.webp';
import magicEggsImage from '../icons/magic_eggs.webp';
import moreEggsImage from '../icons/more_eggs.webp';
import petEquipImage from '../icons/pet_equip.webp';
import ultraLuckyImage from '../icons/ultra_lucky.webp';
import vipImage from '../icons/vip.webp';
import PremiumIcon from '../icons/premium-icon.webp';
import DiscordIcon from '../icons/DISCORD.webp';
import dayCareImage from "../icons/dayCare.webp";
import superDropImage from "../icons/superDrop.webp"
import doubleStarsImage from "../icons/double_star.webp"

import TruncatedNote from "./TruncatedNote"


    export default function ApplicationList(props) {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
        const [imageModalOpen, setImageModalOpen] = useState(false);
        const [officerData, setOfficerData] = useState(false);
        const [modalData, setModalData] = useState({ date: '', fee: '', role: 'member' });
        const [rejectData, setRejectData] = useState({})
        const [messageData, setMessageData] = useState({})
        const [isRejectModalOpen, setRejectModalOpen] = useState(false)
        const [rejectReason, setRejectReason] = useState(''); // Přidáno pro ukládání důvodu odmítnutí
        const [applications, setApplications] = useState([
          {
            avatar: '',
            nick: '',
            robloxId: '',
            displayName: '',
            locale: '',
            discord: '',
            rbxPremium: false,
            titanics: '',
            huges: '',
            dmgPet: '',
            createTime: '',
            sendDate: '',
            note: '',
            playTime: '',
            isMember: false,
            startFee: '',
            gamepasses: {
              lucky: false,
              ultraLucky: false,
              hugeHunter: false,
              magicEggs: false,
              vip: false,
              autofarm: false,
              autotap: false,
              petEquip: false,
              moreEggs: false,
              superDrops: false,
              daycare: false,
              doubleStars: false
            },
            enchantSlots: '',
            messageCount: '',
            voiceCount: '',
            rank: '',
            sub: false,
            booster: false,
            penalty: '',
            giftedSubs: '',
            level: '',
            discordId: '',
            token: '',
            refreshToken: '',
            screens: []
          }
          
        ]);
        const { showAlert } = useAlert();

        const fetchApplications = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${Config.domain}/api/applications/pending2`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            setApplications(data);
          } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
          }
        };

        const fetchOfficerData = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${Config.domain}/api/officer/info`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const data = await response.json();
            setOfficerData(data.officerInfo);
            console.log(data.officerInfo)
          } catch (error) {
            console.error('Error:', error);
          }
        };

        useEffect(() => {
          fetchApplications();
          fetchOfficerData();
        }, []);

        const handleInsertTemplate = () => {
          const template = `Ahoj <@{{discordId}}>,\nzaujala nás tvoje přihláška do VLP clanu, pokud máš stále zájem se k nám přidat, tak prosím napiš mě nebo někomu z officerů!!\nZatím díky,\n<@{{officer}}>`; 
          setMessageData((prevData) => ({
            ...prevData,
            content: template
              .replace('{{discordId}}', messageData.discordId)  // nahrazení discordId
              .replace('{{officer}}', officerData.discordId),     // nahrazení officer
          }));
        };

        const handleSendMessage = async () => {
          const payload = {
            robloxNick: messageData.robloxNick,
            content: messageData.content,
            robloxId: messageData.robloxId,
            discordId: messageData.discordId,
            avatar: messageData.avatar,
            officerDiscordId: officerData.discordId,
            officerDiscord: officerData.officerDiscord,
            officerUserName: officerData.nick,
            officerAvatar: officerData.avatar
          };

        try {
          const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
          const response = await fetch(`${Config.domain}/api/sendMessage`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
          });
      
          if (!response.ok) {
            showAlert(
              `Něco se dojebalo!`,
              'solid',
              'danger'
            );
            throw new Error('Network response was not ok');
           
          }
          setSendMessageModalOpen(false)
          showAlert(
            `Zpráva byla úspěšně odeslána!`,
            'solid',
            'success'
          );
          console.log('Approval sent successfully');
        } catch (error) {
          console.error('There was a problem with sending the data:', error);
        }
      
      };

      const handleSetFavorite = async (nick, discordId, isFavorite) => {
        const token = localStorage.getItem('token');  // nebo jiný způsob, jak získat token
        console.log("isFavorite", isFavorite)
        try {
          const response = await fetch(`${Config.domain}/api/applications/setFavorite`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,  // Přidání tokenu do hlavičky
            },
            body: JSON.stringify({
              nick: nick,
              discordId: discordId,
              favorite: isFavorite,  // true nebo false
            }),
          });
      
          if (response.ok) {
      
            const data = await response.json();
            console.log('Záznam upraven:', data);
            showAlert(
              `Záznam úspěšně přidán do uložených!`,
              'solid',
              'success'
            );
            props.refreshCounts();
          } else {
            console.error('Chyba při změně oblíbených položek');
          }
        } catch (error) {
          console.error('Chyba při odesílání požadavku:', error);
        }
      };
      

        const handleApprove = (applicationNick, startFee) => {
          setModalData({ 
            nick: applicationNick, 
            date: new Date().toISOString().split('T')[0], // Aktuální datum
            fee: formatNumber(startFee),
            role: 'member'
          });
          setIsModalOpen(true);
        };
      
        const handleReject = (applicationNick) => {
          // Reset důvodu odmítnutí při každém otevření modálního okna
          setRejectData({ nick: applicationNick, reason: '' });
          setRejectModalOpen(true);
        };

        const handleMessage = (discordId, robloxNick, avatar) => {
          // Reset důvodu odmítnutí při každém otevření modálního okna
          console.log(discordId, robloxNick, avatar)
          setMessageData({ discordId: discordId, robloxNick: robloxNick, avatar: avatar, content: '' });
          setSendMessageModalOpen(true);
        };

        const handleRejectReasonChange = (e) => {
          setRejectData({ ...rejectData, reason: e.target.value });
        };

        const handleSendMessageChange = (e) => {
          setMessageData((prevData) => ({
            ...prevData,
            content: e.target.value,
          }));
        };


        const handleSubmit = async () => {
          // Příprava payloadu
          const payload = {
            nick: modalData.nick,
            date: modalData.date,
            fee: modalData.fee,
            role: modalData.role
          };
        
          try {
            const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
            const response = await fetch(`${Config.domain}/api/members/approval`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(payload)
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            showAlert(
              `Přihláška byla schválena!`,
              'solid',
              'success'
            );
            // Zde můžeš přidat další logiku po úspěšném odeslání dat
            console.log('Approval sent successfully');
          } catch (error) {
            console.error('There was a problem with sending the data:', error);
          }
        
          setIsModalOpen(false);
          setApplications(prevApplications => 
            prevApplications.filter(app => app.nick !== modalData.nick)
          );
        };

        const closeRejectModal = () => {
          setRejectModalOpen(false);
      };

      const handleConfirmReject = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${Config.domain}/api/member/declined`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(rejectData) // Odesíláme objekt s nickem a důvodem
          })
    
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          closeRejectModal()
          showAlert(
            `Přihláška byla odmítnuta!`,
            'solid',
            'success'
          );
          console.log('Reject sent successfully');
        } catch (error) {
          console.error('There was a problem with sending the data:', error);
        }
    
        setIsModalOpen(false);
        setApplications(prevApplications => 
          prevApplications.filter(app => app.nick !== modalData.nick)
        );
      };
        
        const handleSelectChange = (event, newValue) => {
          setModalData({...modalData, role: newValue});
        };

        function formatNumber(num) {
          const sign = num < 0 ? '-' : '';
          const absNum = Math.abs(num);
      
          if (absNum < 1000) return sign + absNum; //Stovky
          if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
      
          const formatWithOptionalDecimal = (value, unit) => {
              const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
              return sign + number + unit;
          };
      
          if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
          if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
          return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
      }
    

        const translate = (value) => {
          if (value === true) { return "ANO" }
          if (value === false) { return "NE"}
        }

        const renderChips = (app) => {
          const commonStyles = {
            padding: "3px 7px 3px 7px",
            fontFamily: "Baloo 2",
            fontSize: "0.75rem",
            mr: "4px"
          };
        
          return (
            <>
              {app.sub && (
                <Chip
                  sx={{
                    ...commonStyles,
                    backgroundColor: "#54e520",
                    color: "black"
                  }}
                >
                  <strong>Kick Sub</strong>
                </Chip> 
              )}
        
              {app.giftedSubs > 0 && (
                <Chip
                  sx={{
                    ...commonStyles,
                    backgroundColor: "#54e520",
                    color: "black"
                  }}
                >
                  <strong>Gifted Subs:</strong> {app.giftedSubs}
                </Chip>
              )}
        
              {app.booster && (
                <Chip
                  sx={{
                    ...commonStyles,
                    backgroundColor: "#c95cc4"
                  }}
                >
                  <strong>Server Booster</strong>
                </Chip>
              )}
            </>
          );
        };

        const renderGamePasses = (gamepasses) => {

          const gamePassImages = {
            autofarm: autoFarmImage,
            autotap: autoTapImage,
            hugeHunter: hugeHunterImage,
            lucky: luckyImage,
            magicEggs: magicEggsImage,
            moreEggs: moreEggsImage,
            petEquip: petEquipImage,
            ultraLucky: ultraLuckyImage,
            vip: vipImage,
            daycare: dayCareImage,
            superDrops: superDropImage,
            doubleStars: doubleStarsImage,
          };
          const gamePassElements = Object.entries(gamepasses).map(([key, value]) => {
            if (value === true) {
              return (
                <Tooltip sx={{zIndex: 1000}} key={key} title={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}>
                  <img src={gamePassImages[key]} alt={key} style={{ width: '25px', height: '25px'}} />
                </Tooltip>
              );
            }
            return null;
          });

        return gamePassElements; // Toto je správně umístěný return pro celou funkci
      };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >
  {isModalOpen && (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} style={{zIndex: "500"}}>
      <ModalDialog style={{width:"450px", borderRadius:"12px", padding:"40px"}}>
      <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Datum přijetí:</FormLabel>
        <Input 
          style={{borderRadius: "8px"}}
          variant="soft" 
          type="date" 
          value={modalData.date} 
          onChange={(e) => setModalData({...modalData, date: e.target.value})}
        />
        </Stack>
        <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Poplatek:</FormLabel>
        <Input 
          style={{borderRadius: "8px"}}
          variant="soft" 
          value={modalData.fee} 
          onChange={(e) => setModalData({...modalData, fee: e.target.value})}
        />
        </Stack>
        <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Role:</FormLabel>
        <Select
          style={{borderRadius: "8px", marginBottom: "15px"}}
          variant="soft" 
          onChange={handleSelectChange}
          defaultValue="member"
        >
          <Option value="member">Member</Option>
          <Option value="officer">Officer</Option>
        </Select>
        </Stack>
        <Button onClick={handleSubmit}>Potvrdit</Button>
      </ModalDialog>
    </Modal>
  )}

   {isRejectModalOpen && (
    <Modal open={isRejectModalOpen} onClose={() => setRejectModalOpen(false)} style={{zIndex: "500"}}>
        <ModalDialog style={{width:"450px", borderRadius:"12px", padding:"40px"}}>
            <Typography variant="h6" textAlign="center" marginBottom="20px">
              Opravdu chcete odmítnout přihlášku hráče {rejectData.nick}?
            </Typography>
            <Input
                fullWidth
                variant="outlined"
                placeholder="Důvod odmítnutí (nepovinné)"
                value={rejectData.reason}
                onChange={handleRejectReasonChange}
                style={{ marginBottom: '20px', borderRadius: "6px" }}
            />
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end', '& > button': { flex: 'none' } }}>
              <Button
                    color="neutral"
                    variant="outlined"
                    style={{borderRadius: "8px"}}
                    onClick={closeRejectModal}
                >
                    Zrušit
                </Button>
                <Button
                    color="danger"
                    style={{borderRadius: "8px"}}
                    onClick={handleConfirmReject}
                >
                    Odmítnout
                </Button>
            </Box>
        </ModalDialog>
    </Modal>
  )}

{sendMessageModalOpen && (
    <Modal open={sendMessageModalOpen} onClose={() => setSendMessageModalOpen(false)} style={{zIndex: "500"}}>
        <ModalDialog
          sx={{
            width: '700px',
            height: 'max-content',
            borderRadius: '12px',
            padding: '40px',
            overflow: 'auto',
            '@media (max-width:600px)': {
              height: 'max-content',
              overflow: 'auto',
              padding: "10px",
              margin: "0px",
              maxWidth: "100%"
            },
          }}
        > 
            <Typography variant="h3" textAlign="center" marginBottom="20px">
              Poslat zprávu hráči {messageData.robloxNick}
            </Typography>
            <CustomTextarea
              placeholder="Obsah zprávy"
              variant="soft"
              type="text"
              name="MessageContent"
              value={messageData.content}
              onChange={handleSendMessageChange}
              placeholderColor="rgb(230, 181, 255)"
              textColor="#bdadff"
              style={{ borderRadius: "8px" }}
            />
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end', '& > button': { flex: 'none' } }}>
              <Button
                    color="neutral"
                    variant="outlined"
                    style={{borderRadius: "8px"}}
                    onClick={handleInsertTemplate}
                >
                    Šablona - Zájem
                </Button>
                <Button
                    color="success"
                    style={{borderRadius: "8px"}}
                    onClick={handleSendMessage}
                >
                    Odeslat
                </Button>
            </Box>
        </ModalDialog>
    </Modal>
  )}

  {applications.map((app) => (
     <Card
     orientation="horizontal"
     sx={{
       width: '98%',
       borderRadius: "12px",
       flexWrap: 'wrap',
       overflow: 'auto',
       backgroundColor: "#00000057",
       marginBottom: "20px"
     }}
   >
    {imageModalOpen && (
    <Modal open={imageModalOpen} onClose={() => setImageModalOpen(false)} style={{zIndex: "500"}}>
      <ModalDialog style={{width:"85%", height: "100%", borderRadius:"12px", padding:"40px"}}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '88vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '12px',
        overflow: 'auto',
      }}>
          <Box sx={{
            display: 'flex', // Wrap the images in a flex container
            justifyContent: 'center', // Center the content horizontally
            alignItems: 'center', // Center the content vertically (optional)
            flexDirection: 'column',
            gap: 1
        }}>
          {app.screens.map((screen, index) => (
            <img
              key={index}
              src={screen}
              alt={`Screenshot ${index + 1}`}
              style={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
              }}
            />
          ))}
       </Box>
      </Box>
      </ModalDialog>
    </Modal>
  )}

     <CardContent sx={{ width: '100%'}}>
       {/* Dates in top right corner */}
       <Box sx={{
  position: 'absolute',
  top: 16,
  right: 16,
  textAlign: 'right',
  maxWidth: '300px'
}}>
  <Typography level="body-sm" fontSize="0.9rem" textColor="text.tertiary">
    Přihláška Odeslána: {new Date(app.sendDate).toLocaleDateString('cs-CZ')}
  </Typography>
  <Typography level="body-sm" fontSize="0.9rem" textColor="text.tertiary">
    Datum registrace na robloxu: {new Date(app.createTime).toLocaleDateString('cs-CZ')}
  </Typography>
  <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginTop: '10px' }}>
    <Button
      onClick={() => setImageModalOpen(true)}
      size="sm"
      variant="outlined"
      color="neutral"
      sx={{ borderRadius: "7px" }}
      startDecorator={<PermMediaIcon sx={{fontSize: "1.2rem"}}/>}
    >
      Přijatých screenů: {app.screens.length}
    </Button>
    <div
      onClick={() => {window.open(`https://discord.com/users/${app.discordId}`, '_blank');}}
      style={{
        width: '50px',
        height: '32px',
        backgroundColor: '#5865F2',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      }}
    >
      <img 
        src={DiscordIcon}
        alt="D"
        style={{
          width: 'auto',
          height: '17px'
        }}
      />
    </div>
  </div>
</Box>
   
       {/* Main content with strict left alignment */}
       <Box sx={{ 
         display: 'flex', 
         gap: 2, 
         pr: '320px',
         alignItems: 'flex-start' // Ensures alignment from the top
       }}>
         {/* Avatar */}
         <Box sx={{ flexShrink: 0 }}> {/* Prevents image from shrinking */}
           <img 
             src={app.avatar}
             alt={app.nick}
             style={{
               width: '100px',
               height: '100px',
               borderRadius: '8px',
               objectFit: 'cover'
             }}
           />
         </Box>
         
         {/* Text content - strictly left-aligned */}
         <Box sx={{ 
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'flex-start', // Forces left alignment
           gap: '4px',
           minWidth: 0
         }}>
           {/* Nick with premium badge */}
           <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
             {app.rbxPremium && (
               <img 
                 src={PremiumIcon}
                 alt="Premium"
                 sx={{ 
                   width: '18px', 
                   height: '18px',
                   mb: 5
                 }}
               />
             )}
             <Typography fontSize="1.6rem" fontWeight="700" sx={{color: "white"}}>
               <a 
                 style={{color: "white"}} 
                 href={`https://www.roblox.com/users/${app.robloxId}/profile`} 
                 target="_blank" 
                 rel="noopener noreferrer"
               >
                 {app.nick}
               </a>
               <Typography 
                 component="span" 
                 sx={{ 
                   color: '#d8d8d8',
                   ml: 1,
                   fontSize: '1.3rem',
                   fontWeight: 400,
                 }}
               >
                 ({app.displayName})
               </Typography>
               <Typography 
                 component="span" 
                 sx={{ 
                   color: '#d8d8d8',
                   ml: 1,
                   fontSize: '1.4rem',
                   fontWeight: 300
                 }}
               >
                 {app.locale}
               </Typography>
             </Typography>
           </Box>
          
           {/* Stats line */}
           <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="text.tertiary" sx={{pt: "3px", pb: "3px"}}>
           <Chip color="danger" size="sm" variant="solid" sx={{padding: "3px 7px 3px 7px", fontFamily: "Baloo 2", fontSize: "0.75rem"}}><strong>Titanics: {app.titanics}</strong></Chip>  <Chip color="warning" size="sm" variant="solid" sx={{padding: "3px 7px 3px 7px", fontFamily: "Baloo 2", fontSize: "0.75rem"}}><strong>Huges: {app.huges}</strong></Chip> | <Chip color="primary" size="sm" variant="solid" sx={{padding: "3px 7px 3px 7px", fontFamily: "Baloo 2", fontSize: "0.75rem"}}><strong>Zpráv: {app.messageCount}</strong></Chip> <Chip color="neutral" size="sm" variant="solid" sx={{padding: "3px 7px 3px 7px", fontFamily: "Baloo 2", fontSize: "0.75rem"}}><strong>Voice: {app.voiceTime}</strong></Chip> <Chip size="sm" variant="solid" sx={{padding: "3px 7px 3px 7px", fontFamily: "Baloo 2", fontSize: "0.75rem", backgroundColor: "#ada335", color: "white"}}><strong>Tresty: {app.penalty}</strong></Chip> {renderChips(app)} 
           </Typography>
   
           {/* Note */}
           <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="#f9f7c0" sx={{pb: "2px", wordBreak: 'break-word', textAlign: "left", fontFamily: "Patrick Hand",   lineHeight: "1.2"}}>
             <TruncatedNote note={app.note} />
           </Typography>
         </Box>
       </Box>
   
       <Sheet
            sx={{
              bgcolor: '#2a244c',
              borderRadius: '8px',
              p: 1.5,
              pr: 3,
              pl: 3,
              my: 1.5,
              display: 'flex',
              gap: 2,
              pt: 2,
              mt: 0,
              mb: 1,
              '& > div': { flex: 1 },
            }}
          >
         <div>
           <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
             Denně spuštěné PS99
           </Typography>
           <Typography fontWeight="lg">{app.playTime} hodin</Typography>
         </div>
         <div>
           <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
             Discord
           </Typography>
           <Typography fontWeight="lg">{app.discord}</Typography>
         </div>
         <div>
           <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
             Rank
           </Typography>
           <Typography fontWeight="lg">{app.rank}</Typography>
         </div>
         <div>
           <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
             Po přijetí zaplatí
           </Typography>
           <Typography fontWeight="lg">{formatNumber(app.startFee)}</Typography>
         </div>
         <div>
           <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
             GamePasses
           </Typography>
           <Typography fontWeight="lg">
             <Box sx={{ display: 'flex', gap: '3px' }}>
               {renderGamePasses(app.gamepasses)}
             </Box>
           </Typography>
         </div>
       </Sheet>
   
       <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
       <Button
           color="warning"
           style={{borderRadius: "8px"}}
           startDecorator={<FavoriteBorderOutlinedIcon />}
           onClick={() => handleSetFavorite(app.nick, app.discordId, true)}
         >
           Uložit
         </Button>
         <Button
           color="primary"
           style={{borderRadius: "8px"}}
           startDecorator={<ChatBubbleOutlineOutlinedIcon />}
           onClick={() => handleMessage(app.discordId, app.nick, app.avatar)}
           
         >
           Zpráva
         </Button>
         <Button
           color="danger"
           style={{borderRadius: "8px"}}
           startDecorator={<CloseIcon />}
           onClick={() => handleReject(app.nick)}
         >
           Zamítnout
         </Button>
         <Button
           color="success"
           style={{borderRadius: "8px"}}
           startDecorator={<CheckIcon />}
           onClick={() => handleApprove(app.nick, app.startFee)}
         >
           Schválit
         </Button>
       </Box>
     </CardContent>
   </Card>
      ))}
    </Box>
  )}