import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Sheet,
  Tooltip,
  Modal,
  ModalDialog,
  Input,
  Select,
  Option,
  Stack,
  FormLabel,
  Grid,
  AspectRatio
} from '@mui/joy';

import CustomTextarea from "./CustomTextarea"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Config from '../Config';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { useAlert } from './AlertContext';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

//obrázky
import autoFarmImage from '../icons/auto_farm.webp';
import autoTapImage from '../icons/auto_tap.webp';
import hugeHunterImage from '../icons/huge_hunter.webp';
import luckyImage from '../icons/lucky.webp';
import magicEggsImage from '../icons/magic_eggs.webp';
import moreEggsImage from '../icons/more_eggs.webp';
import petEquipImage from '../icons/pet_equip.webp';
import ultraLuckyImage from '../icons/ultra_lucky.webp';
import vipImage from '../icons/vip.webp';
import enchantImage from '../icons/enchant.webp';
import dayCareImage from "../icons/dayCare.webp";
import superDropImage from "../icons/superDrop.webp"
import doubleStarsImage from "../icons/double_star.webp"


export default function ApplicationsListOmluvenka(props) {
  const [applications, setApplications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [rejectData, setRejectData] = useState({})
  const [isRejectModalOpen, setRejectModalOpen] = useState(false)
  const [rejectReason, setRejectReason] = useState(''); // Přidáno pro ukládání důvodu odmítnutí
  const [messageData, setMessageData] = useState({})
  const [officerData, setOfficerData] = useState(false);
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [isHolidayModalOpen, setisHolidayModalOpen] = useState(false);

  const { showAlert } = useAlert();


  const fetchApplications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/applications/altomluvenky`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setApplications(data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const fetchOfficerData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/officer/info`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setOfficerData(data.officerInfo);
      console.log(data.officerInfo)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchApplications();
    fetchOfficerData();
  }, []);

  const fetchAll = async() => {
    fetchApplications();
    fetchOfficerData();
  }

  const handleApprove = (id, avatar, discordId, message, startDate, endDate, memberId) => {
    setModalData({
      id: id,
      avatar: avatar, // Aktuální datum
      discordId: discordId,
      message: message,
      startDate: formatDatum(startDate),
      endDate: formatDatum(endDate),
      memberId: memberId
    });
    setIsModalOpen(true);
  };


  const handleSetHoliDays = async(id, avatar, discordId, message, startDate, endDate, memberId) => {
    setModalData({
      id: id,
      avatar: avatar, // Aktuální datum
      discordId: discordId,
      message: message,
      startDate: formatDatum(startDate),
      endDate: formatDatum(endDate),
      memberId: memberId
    });
    setisHolidayModalOpen(true)
  };


  const submbitHoliDays = async () => {
    const payload = {
      data: { modalData, officerData },
    };

    try {
      const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
      const response = await fetch(`${Config.domain}/api/excuse/setholidays`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        showAlert(
          `Něco se dojebalo!`,
          'solid',
          'danger'
        );
        throw new Error('Network response was not ok');
      }
      showAlert(
        `Hráč poslán na dovolenou a byl odstraněn z členů!`,
        'solid',
        'success'
      );
      setisHolidayModalOpen(false)
      await fetchAll()
      props.refreshCounts();
      // Zde můžeš přidat další logiku po úspěšném odeslání dat
      console.log('Approval sent successfully');
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

    setApplications(prevApplications =>
      prevApplications.filter(app => app.id !== modalData.id)
    );
  };

  const handleReject = (applicationNick) => {
    // Reset důvodu odmítnutí při každém otevření modálního okna
    setRejectData({ nick: applicationNick, reason: '' });
    setRejectModalOpen(true);
  };

  function formatDatum(isoDatum) {
    const datum = new Date(isoDatum);
    const den = String(datum.getDate()).padStart(2, '0');
    const mesic = String(datum.getMonth() + 1).padStart(2, '0'); // Měsíce jsou indexovány od 0
    const rok = datum.getFullYear();

    return `${den}. ${mesic}. ${rok}`;
  }

  const handleRejectReasonChange = (e) => {
    setRejectData({ ...rejectData, reason: e.target.value });
  };

  const handleSubmit = async () => {
    // Příprava payloadu
    const payload = {
      data: { modalData, officerData },
    };

    try {
      const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
      const response = await fetch(`${Config.domain}/api/excuse/approve`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        showAlert(
          `Něco se dojebalo!`,
          'solid',
          'danger'
        );
        throw new Error('Network response was not ok');
      }
      showAlert(
        `Dovolená schválena, hráč byl informován!`,
        'solid',
        'success'
      );
      await fetchAll()
      // Zde můžeš přidat další logiku po úspěšném odeslání dat
      console.log('Approval sent successfully');
      props.refreshCounts();
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

    setIsModalOpen(false);
  };


  const closeSubmitModal = () => {
    setIsModalOpen(false);
  };

  const closeRejectModal = () => {
    setRejectModalOpen(false);
  };

  const handleInsertTemplate = () => {
    const template = `Ahoj <@{{discordId}}>,\nzaujala nás tvoje přihláška do VLP clanu, pokud máš stále zájem se k nám přidat, tak prosím napiš mě nebo někomu z officerů!!\nZatím díky,\n<@{{officer}}>`;
    setMessageData((prevData) => ({
      ...prevData,
      content: template
        .replace('{{discordId}}', messageData.discordId)  // nahrazení discordId
        .replace('{{officer}}', officerData.discordId),     // nahrazení officer
    }));
  };

  const handleConfirmReject = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/member/delete`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(rejectData) // Odesíláme objekt s nickem a důvodem
      })

      if (!response.ok) {
        showAlert(
          `Něco se dojebalo!`,
          'solid',
          'danger'
        );
        throw new Error('Network response was not ok');
      }
      closeRejectModal()
      fetchApplications()
      showAlert(
        `Hráč byl úspěšně vyhozen!`,
        'solid',
        'success'
      );

      props.refreshCounts();
      console.log('Reject sent successfully');
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

    setIsModalOpen(false);
    setApplications(prevApplications =>
      prevApplications.filter(app => app.nick !== modalData.nick)
    );
  };

  const handleSendMessage = async () => {
    const payload = {
      robloxNick: messageData.robloxNick,
      content: messageData.content,
      robloxId: messageData.robloxId,
      discordId: messageData.discordId,
      avatar: messageData.avatar,
      officerDiscordId: officerData.discordId,
      officerDiscord: officerData.officerDiscord,
      officerUserName: officerData.nick,
      officerAvatar: officerData.avatar
    };

    try {
      const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
      const response = await fetch(`${Config.domain}/api/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        showAlert(
          `Něco se dojebalo!`,
          'solid',
          'danger'
        );
        throw new Error('Network response was not ok');

      }
      showAlert(
        `Zpráva byla úspěšně odeslána!`,
        'solid',
        'success'
      );
      setSendMessageModalOpen(false);
      console.log('Approval sent successfully');
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

  };

  const handleSendMessageChange = (e) => {
    setMessageData((prevData) => ({
      ...prevData,
      content: e.target.value,
    }));
  };

  const handleMessage = (discordId, robloxNick, avatar) => {
    // Reset důvodu odmítnutí při každém otevření modálního okna
    console.log(discordId, robloxNick, avatar)
    setMessageData({ discordId: discordId, robloxNick: robloxNick, avatar: avatar, content: '' });
    setSendMessageModalOpen(true);
  };


  const handleSelectChange = (event, newValue) => {
    setModalData({ ...modalData, role: newValue });
  };

  function formatNumber(num) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);

    if (absNum < 1000) return sign + absNum; //Stovky
    if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce

    const formatWithOptionalDecimal = (value, unit) => {
      const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
      return sign + number + unit;
    };

    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
    return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
  }


  const translate = (value) => {
    if (value === true) { return "ANO" }
    if (value === false) { return "NE" }
  }

  const renderGamePasses = (gamepasses) => {

    const gamePassImages = {
      autofarm: autoFarmImage,
      autotap: autoTapImage,
      hugeHunter: hugeHunterImage,
      lucky: luckyImage,
      magicEggs: magicEggsImage,
      moreEggs: moreEggsImage,
      petEquip: petEquipImage,
      ultraLucky: ultraLuckyImage,
      vip: vipImage,
      daycare: dayCareImage,
      superDrops: superDropImage,
      enchant: enchantImage,
      doubleStars: doubleStarsImage,
    };
    const gamePassElements = Object.entries(gamepasses).map(([key, value]) => {
      if (value === true) {
        return (
          <Tooltip key={key} title={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}>
            <img src={gamePassImages[key]} alt={key} style={{ width: '25px', height: '25px' }} />
          </Tooltip>
        );
      }
      return null;
    });

    return gamePassElements; // Toto je správně umístěný return pro celou funkci
  };
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >
      {sendMessageModalOpen && (
        <Modal open={sendMessageModalOpen} onClose={() => setSendMessageModalOpen(false)} style={{ zIndex: "500" }}>
          <ModalDialog
            sx={{
              width: '700px',
              height: 'max-content',
              borderRadius: '12px',
              padding: '40px',
              overflow: 'auto',
              '@media (max-width:600px)': {
                height: 'max-content',
                overflow: 'auto',
                padding: "10px",
                margin: "0px",
                maxWidth: "100%"
              },
            }}
          >
            <Typography variant="h3" textAlign="center" marginBottom="20px">
              Poslat zprávu hráči {messageData.robloxNick}
            </Typography>
            <CustomTextarea
              placeholder="Obsah zprávy"
              variant="soft"
              type="text"
              name="MessageContent"
              value={messageData.content}
              onChange={handleSendMessageChange}
              placeholderColor="rgb(230, 181, 255)"
              textColor="#bdadff"
              style={{ borderRadius: "8px" }}
            />
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end', '& > button': { flex: 'none' } }}>
              <Button
                color="neutral"
                variant="outlined"
                style={{ borderRadius: "8px" }}
                onClick={handleInsertTemplate}
              >
                Šablona - Zájem
              </Button>
              <Button
                color="success"
                style={{ borderRadius: "8px" }}
                onClick={handleSendMessage}
              >
                Odeslat
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}

      {isModalOpen && (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} style={{ zIndex: "500" }}>
          <ModalDialog style={{ width: "450px", borderRadius: "12px", padding: "40px" }}>
            <Typography variant="h6" textAlign="center" marginBottom="20px">
              Potvrdit dovolenou hráče {rejectData.nick}?
            </Typography>
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'center', '& > button': { flex: 'none' } }}>
              <Button
                color="neutral"
                variant="outlined"
                style={{ borderRadius: "8px" }}
                onClick={closeSubmitModal}
              >
                Zrušit
              </Button>
              <Button
                color="success"
                style={{ borderRadius: "8px" }}
                onClick={handleSubmit}
              >
                Potvrdit
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}

{isHolidayModalOpen && (
        <Modal open={isHolidayModalOpen} onClose={() => setisHolidayModalOpen(false)} style={{ zIndex: "500" }}>
          <ModalDialog style={{ width: "450px", borderRadius: "12px", padding: "40px" }}>
            <Typography variant="h6" textAlign="center" marginBottom="20px">
              Poslat hráče {rejectData.nick} na dovolenou? Tzn. hráč bude z clanu vyhozen do příští CW?
            </Typography>
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'center', '& > button': { flex: 'none' } }}>
              <Button
                color="neutral"
                variant="outlined"
                style={{ borderRadius: "8px" }}
                //onClick={() => setisHolidayModalOpen(false)}
              >
                Zrušit
              </Button>
              <Button
                color="success"
                style={{ borderRadius: "8px" }}
                onClick={submbitHoliDays}
              >
                Potvrdit
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}

      {isRejectModalOpen && (
        <Modal open={isRejectModalOpen} onClose={() => setRejectModalOpen(false)} style={{ zIndex: "500" }}>
          <ModalDialog style={{ width: "450px", borderRadius: "12px", padding: "40px" }}>
            <Typography variant="h6" textAlign="center" marginBottom="20px">
              Opravdu chcete odmítnout přihlášku hráče {rejectData.nick}?
            </Typography>
            <Input
              fullWidth
              variant="outlined"
              placeholder="Důvod odmítnutí (nepovinné)"
              value={rejectData.reason}
              onChange={handleRejectReasonChange}
              style={{ marginBottom: '20px', borderRadius: "6px" }}
            />
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end', '& > button': { flex: 'none' } }}>
              <Button
                color="neutral"
                variant="outlined"
                style={{ borderRadius: "8px" }}
                onClick={closeRejectModal}
              >
                Zrušit
              </Button>
              <Button
                color="danger"
                style={{ borderRadius: "8px" }}
                onClick={handleConfirmReject}
              >
                Odmítnout
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}

      {applications.map((app) => (
        <Card
          orientation="horizontal"
          sx={{
            width: '97%',
            borderRadius: "12px",
            flexWrap: 'wrap',
            overflow: 'auto',
            backgroundColor: "#00000057",
            marginBottom: "20px"
          }}
        >
          <CardContent>
            <Box sx={{
              display: 'flex',
              gap: 2,
              pr: '320px',
              alignItems: 'flex-start'
            }}>
              <Box sx={{ flexShrink: 0 }}>
                <img
                  src={app.avatar}
                  alt={app.nick}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '8px',
                    objectFit: 'cover'
                  }}
                />
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px', // Místo gap mezi texty
                minWidth: 0
              }}>
                <Typography fontSize="1.4rem" fontWeight="700" sx={{ color: "white" }}>
                  <a
                    style={{ color: "white" }}
                    href={`https://www.roblox.com/users/${app.robloxId}/profile`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {app.nick}
                  </a>
                </Typography>
                <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="text.tertiary">
                  Discord: {app.discord}
                </Typography>

                <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="text.tertiary">
                  <strong>Důvod nepřítomnosti:</strong> {app.message}
                </Typography>
              </Box>
            </Box>


            <Sheet
              sx={{
                bgcolor: '#2a244c',
                borderRadius: '8px',
                p: 1.5,
                pr: 3,
                pl: 3,
                my: 1.5,
                display: 'flex',
                gap: 2,
                pt: 2,
                mt: 0,
                '& > div': { flex: 1 },
              }}
            >
              <div>
                <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                  Nepřítomnost od:
                </Typography>
                <Typography fontWeight="lg">{formatDatum(app.startDate)}</Typography>
              </div>
              <div>
                <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                  Nepřítomnost do:
                </Typography>
                <Typography fontWeight="lg">{formatDatum(app.endDate)}</Typography>
              </div>
              <div>
                <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                  Délka dovolené:
                </Typography>
                <Typography fontWeight="lg">{app.duration} dny</Typography>
              </div>
              <div>
                <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                  GamePasses
                </Typography>
                <Typography fontWeight="lg">
                  <Box sx={{ display: 'flex', gap: '3px' }}>
                    {renderGamePasses(app.gamepasses)}

                  </Box>
                </Typography>
              </div>
            </Sheet>
            <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
              {app.status === "pending" && (
                <>
                  <Button
                    color="warning"
                    style={{ borderRadius: "8px" }}
                    startDecorator={<BeachAccessIcon />}
                    onClick={() => handleSetHoliDays(app._id, app.avatar, app.discordId, app.message, app.startDate, app.endDate, app.memberId)}
                  >
                    Poslat na dovolenou
                  </Button>
                  <Button
                    color="primary"
                    style={{ borderRadius: "8px" }}
                    startDecorator={<ChatBubbleOutlineOutlinedIcon />}
                    onClick={() => handleMessage(app.discordId, app.nick, app.avatar)}
                  >
                    Zpráva
                  </Button>
                  <Button
                    color="danger"
                    style={{ borderRadius: "8px" }}
                    startDecorator={<CloseIcon />}
                    onClick={() => handleReject(app.nick)}
                  >
                    Vyhodit
                  </Button>
                  <Button
                    color="success"
                    style={{ borderRadius: "8px" }}
                    startDecorator={<CheckIcon />}
                    onClick={() => handleApprove(app._id, app.avatar, app.discordId, app.message, app.startDate, app.endDate, app.memberId)}
                  >
                    Potvrdit
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}