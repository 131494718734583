import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Input, Button, FormLabel, Stack, Alert, Modal, ModalDialog } from '@mui/joy/';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SavingsIcon from '@mui/icons-material/Savings';
import WebhookIcon from '@mui/icons-material/Webhook';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Config from '../Config';
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function AdminSettings() {
    const [settingsData, setSettings] = useState({
        dailyFee: '',
        clanGems: '',
        discordWebhook: '',
        cwTargetI: '', // Nové
        cwTargetII: '', // Nové
        cwTargetIII: '', // Nové
        medails: "",
        endLotto: "",
        welcomeMess: ""
    });

    const [alert, setAlert] = useState({
        color: '', 
        message: '',
        show: false,
    });
    const [memberCount, setMemberCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [applications, setApplications] = useState({})
    const [removeNick, setRemoveNick] = useState('');
    const [moveToVLP2Nick, setMoveToVLP2Nick] = useState('');
    const [moveToVLPNick, setMoveToVLPNick] = useState('');
    const [resetNick, setResetNick] = useState("")


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const removeMember = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/member/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ nick: removeNick }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            setAlert({ color: 'success', message: 'Hráč byl úspěšně odstraněn.', show: true });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setAlert({ color: 'error', message: 'Chyba při odstraňování hráče!', show: true });
        }
    };
    
    const moveMemberToVLP2 = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/member/move`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ nick: moveToVLP2Nick, clan: 'vlp2' }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setAlert({ color: 'success', message: 'Hráč byl úspěšně přesunut do VLP2.', show: true });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setAlert({ color: 'error', message: 'Chyba při přesouvání hráče do VLP2!', show: true });
        }
    };
    
    const moveMemberToVLP = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/member/move`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ nick: moveToVLPNick, clan: 'vlp' }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            setAlert({ color: 'success', message: 'Hráč byl úspěšně přesunut do VLP.', show: true });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setAlert({ color: 'error', message: 'Chyba při přesouvání hráče do VLP!', show: true });
        }
    };

    const resetCD = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/resetcd`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ nick: resetNick }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setAlert({ color: 'success', message: 'Úspěšně resetován cooldown, hráč může poslat přihlášku.', show: true });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setAlert({ color: 'error', message: 'Chyba při restartu cooldownu!', show: true });
        }
    };
    
    const fetchSettings = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/settings/data`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.length > 0) {
                setSettings(data[0]);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const fetchMemberCount = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${Config.domain}/api/members`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const membersData = await response.json();
            setMemberCount(membersData.length);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };


    const handleResetCW = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${Config.domain}/api/points/reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "reset": "lol" }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            closeModal();
            setAlert({ color: 'success', message: 'Milníky CW úspěšně aktualizovány!', show: true });
        } catch (error) {
            console.error('There was a problem with the update operation:', error);
            setAlert({ color: 'error', message: 'Chyba při aktualizaci milníků CW!', show: true });
        }
    };
    

    useEffect(() => {
        fetchSettings();
        fetchMemberCount();
    }, []);

    const calculateIncome = (days) => {
        let convertNum;
      if (/^\d+[kmibt]$/i.test(settingsData.dailyFee)) {
        convertNum = parseNumber(settingsData.dailyFee);
      } else {
        convertNum = parseInt(settingsData.dailyFee);
      }
        return formatNumber(memberCount * convertNum * days);
    }

    const calculateClanGems = (days) => {
        let convertDailyFee;
        if (/^\d+[kmibt]$/i.test(settingsData.dailyFee)) {
            convertDailyFee = parseNumber(settingsData.dailyFee);
        } else {
            convertDailyFee = parseInt(settingsData.dailyFee);
        }
    
        let convertClanGems;
        if (/^\d+[kmibt]$/i.test(settingsData.clanGems)) {
            convertClanGems = parseNumber(settingsData.clanGems);
        } else {
            convertClanGems = parseInt(settingsData.clanGems);
        }
    
        return formatNumber(memberCount * convertDailyFee * days + convertClanGems);
    }
    

    const handleUpdateSetting = async (key, value) => {
       console.log("key: ", key, "value", value)
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${Config.domain}/api/settings/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ [key]: value }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setAlert({ color: 'success', message: 'Úspěšně aktualizováno!', show: true });
        } catch (error) {
            console.error('There was a problem with the update operation:', error);
            setAlert({ color: 'error', message: 'Chyba při aktualizaci!', show: true });
        }
    };

    function formatNumber(num, isCWPoints = false) {
        const sign = num < 0 ? '-' : '';
        const absNum = Math.abs(num);
      
        // Speciální formátování pro CW body
        if (isCWPoints) {
            if (absNum < 1000) return sign + absNum; // Pokud je méně než 1000, zobrazit jak je
            if (absNum < 1000000) return sign + (absNum / 1000).toFixed(2) + 'k'; // Pro tisíce s dvěma desetinnými místy
            // Přidejte podmínky pro miliony atd., pokud je to potřeba
        } else {
            // Původní logika pro ostatní hodnoty
            if (absNum < 1000) return sign + absNum; //Stovky
            if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
      
            const formatWithOptionalDecimal = (value, unit) => {
                const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
                return sign + number + unit;
            };
      
            if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
            if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
            return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
        }
      
        // Vrátí původní hodnotu pro jiné případy, které nejsou zahrnuty výše
        return sign + absNum;
      }

    function parseNumber(str) {
        const units = {
          k: 1000,
          m: 1000000,
          b: 1000000000,
          t: 1000000000000
        };
      
        if (typeof str !== 'string') {
          return str;
        }
      
        const unit = str.toLowerCase().match(/[kmibt]+$/)
        const number = parseFloat(str, 10);
      
        if (unit && units[unit[0]]) {
          return number * units[unit[0]];
        }
      
        return number;
      }
    
    return (

        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: { xs: 'auto', sm: 'initial' },
            }}
        >
            {alert.show && (
            <Alert sx={{width: "97%", marginBottom: "15px", borderRadius: "8px"}} color={alert.color} onClose={() => setAlert({ ...alert, show: false })}>
                {alert.message}
            </Alert>
        )}
        {isModalOpen && (
    <Modal open={isModalOpen} onClose={closeModal} style={{zIndex: "500"}}>
      <ModalDialog style={{ width: "350px", borderRadius: "12px", padding: "40px" }}>
        <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: "15px" }}>Opravdu chcete smazat CW body všem uživatelům?</Typography>
        <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
          <Button
            color="neutral"
            variant="outlined"
            style={{borderRadius: "8px"}}
            onClick={closeModal}
          >
            NE
          </Button>
          <Button
            color="danger"
            style={{borderRadius: "8px"}}
            onClick={handleResetCW}
          >
            <strong>ANO</strong>
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
)}
            <Card
                orientation="horizontal"
                sx={{
                    width: '97%',
                    flexWrap: 'wrap',
                    backgroundColor: "#1d1933",
                    border: "none",
                    paddingRight: "20px",
                    paddingTop: "30px"
                  }}

            >
              
                  <Stack direction="row" spacing={2} sx={{ width: '100%', padding: 2 }}>
                          {/* Nové inputy pro CW cíle */}
                          <Stack flexGrow={1} spacing={1}>
                        <FormLabel>Poslat welcome zprávu:</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<SendRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('welcomeMess', settingsData.welcomeMess)}>Nastavit</Button>}
                            placeholder={"Discord ID nováčka"}
                            onChange={(e) => setSettings({...settingsData, welcomeMess: e.target.value})}
                        />
                    </Stack>
                    <Stack flexGrow={1} spacing={1}>
                        <FormLabel>Získané medaile:</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<EmojiEventsRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('medails', settingsData.medails)}>Nastavit</Button>}
                            placeholder={settingsData.medails}
                            onChange={(e) => setSettings({...settingsData, medails: e.target.value})}
                        />
                    </Stack>
                    <Stack flexGrow={1} spacing={1}>
                        <FormLabel>Slosování loterie (DD. MM. RRRR HH:MM):</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<EmojiEventsRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('endLotto', settingsData.endLotto)}>Nastavit</Button>}
                            placeholder="DD. MM. RRRR HH:MM"
                            onChange={(e) => setSettings({...settingsData, endLotto: e.target.value})}
                        />
                    </Stack>
                    </Stack>
                  <Stack direction="row" spacing={2} sx={{ width: '100%', padding: 2 }}>
                          {/* Nové inputy pro CW cíle */}
                          <Stack flexGrow={1} spacing={1}>
                        <FormLabel>{"CWbody >= X (zelená):"}</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<FlagRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('green', settingsData.green)}>Nastavit</Button>}
                            placeholder={formatNumber(settingsData.green, true)}
                            onChange={(e) => setSettings({...settingsData, green: e.target.value})}
                        />
                    </Stack>
                    <Stack flexGrow={1} spacing={1}>
                        <FormLabel>{"CWbody >= X (oranžová):"}</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<FlagRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('orange', settingsData.orange)}>Nastavit</Button>}
                            placeholder={formatNumber(settingsData.orange, true)}
                            onChange={(e) => setSettings({...settingsData, orange: e.target.value})}
                        />
                    </Stack>
                    <Stack flexGrow={1} spacing={1}>
                        <FormLabel>{"Resetovat CD pro přihlášku:"}</FormLabel>
                        <Input
                            fullWidth
                            size="xl"
                            style={{borderRadius: "6px"}}
                            startDecorator={<AccessTimeIcon style={{ fontSize: 30, paddingLeft: 8 }}/>}
                            endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={resetCD}>Nastavit</Button>}
                            placeholder="Nick"
                            onChange={(e) => setResetNick(e.target.value)}
                        />
                    </Stack>
                    </Stack>
                <Stack direction="row" spacing={2} sx={{ width: '100%', padding: 2 }}>
        
                 <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Nastavit denní poplatek:</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<PriceChangeIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('dailyFee', settingsData.dailyFee)}>Nastavit</Button>}
                    placeholder={formatNumber(settingsData.dailyFee)}
                    onChange={(e) => setSettings({...settingsData, dailyFee: e.target.value})}
                />
                </Stack>
                <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Clan gems:</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<SavingsIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('clanGems', settingsData.clanGems)}>Nastavit</Button>}
                    placeholder={formatNumber(settingsData.clanGems)}
                    onChange={(e) => setSettings({...settingsData, clanGems: e.target.value})}
                />
                </Stack>
                <Stack flexGrow={1} spacing={1}>
                <FormLabel style={{paddingLeft: "2px"}}>Private server (URL):</FormLabel>
                <Input
                    fullWidth
                    size="xl"
                    style={{borderRadius: "6px"}}
                    startDecorator={<SportsEsportsRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
                    endDecorator={<Button style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} onClick={() => handleUpdateSetting('webhook', settingsData.webhook)}>Nastavit</Button>}
                    placeholder={settingsData.webhook}
                    onChange={(e) => setSettings({...settingsData, webhook: e.target.value})}
                />
                </Stack>
                </Stack>   
                <Stack direction="row" spacing={2} sx={{ width: '100%', padding: 2 }}>
  <Stack flexGrow={1} spacing={1}>
    <FormLabel>Smazat hráče z db (nick):</FormLabel>
    <Input
        fullWidth
        size="xl"
        style={{borderRadius: "6px"}}
        startDecorator={<PersonRemoveAlt1RoundedIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
        endDecorator={
            <Button 
                style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} 
                onClick={removeMember}
            >
                Odeslat
            </Button>
        }
        placeholder={"Nick hráče"}
        onChange={(e) => setRemoveNick(e.target.value)}
    />
  </Stack>
  <Stack flexGrow={1} spacing={1}>
    <FormLabel>Přesunout hráče do VLP2 (nick):</FormLabel>
    <Input
        fullWidth
        size="xl"
        style={{borderRadius: "6px"}}
        startDecorator={<ExitToAppRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
        endDecorator={
            <Button 
                style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} 
                onClick={moveMemberToVLP2}
            >
                Přesunout
            </Button>
        }
        placeholder={"Nick hráče"}
        onChange={(e) => setMoveToVLP2Nick(e.target.value)}
    />
  </Stack>
  <Stack flexGrow={1} spacing={1}>
    <FormLabel>Přesunout hráče do VLP (nick):</FormLabel>
    <Input
        fullWidth
        size="xl"
        style={{borderRadius: "6px"}}
        startDecorator={<ExitToAppRoundedIcon style={{ fontSize: 30, paddingLeft: 8 }} />}
        endDecorator={
            <Button 
                style={{ backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)", borderRadius: "0px 7px 7px 0px"}} 
                onClick={moveMemberToVLP}
            >
                Přesunout
            </Button>
        }
        placeholder={"Nick hráče"}
        onChange={(e) => setMoveToVLPNick(e.target.value)}
    />
  </Stack>
</Stack>
                  
                <Stack spacing={0} style={{paddingLeft: "16px"}}>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 1 den:</strong> {calculateIncome(1)} (celkový odhad gemů: {calculateClanGems(1)})</Typography>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 7 dní:</strong> {calculateIncome(7)} (celkový odhad gemů: {calculateClanGems(7)})</Typography>
                    <Typography fontSize="0.9rem"><strong>Předpokládaný příjem za 30 dní:</strong> {calculateIncome(30)} (celkový odhad gemů: {calculateClanGems(30)})</Typography>
        </Stack>
            </Card>
        </Box>
    )
}