import { createContext, useContext, useState } from 'react';
import { Alert } from '@mui/joy';

const AlertContext = createContext(null);

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const showAlert = (message, variant = 'solid', color = 'danger', duration = 10000) => {
    const id = Date.now();
    
    setAlerts(prevAlerts => [...prevAlerts, { id, message, variant, color }]);

    if (duration !== null) {
      setTimeout(() => {
        closeAlert(id);
      }, duration);
    }

    return id; // Vrátíme ID pro případné manuální zavření
  };

  const closeAlert = (id) => {
    setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      <div style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        maxWidth: '550px'
      }}>
        {alerts.map(({ id, message, variant, color }) => (
          <Alert
            key={id}
            variant={variant}
            color={color}
            endDecorator={
              <button 
                onClick={() => closeAlert(id)}
                style={{ 
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '4px'
                }}
              >
                ✕
              </button>
            }
            sx={{
              animation: 'slideIn 0.3s ease-out',
              '@keyframes slideIn': {
                from: {
                  transform: 'translateX(100%)',
                  opacity: 0
                },
                to: {
                  transform: 'translateX(0)',
                  opacity: 1
                }
              }
            }}
          >
            {message}
          </Alert>
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};