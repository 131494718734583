import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import LinearProgress from '@mui/joy/LinearProgress';
import Button from '@mui/joy/Button';
import Box from "@mui/joy/Box";
import { Typography, Alert, Chip } from '@mui/joy';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MAX_FILES = 10;
const MAX_SIZE_MB = 20;

const FileUpload = ({ formData, setFormData, onRegistrationComplete }) => {
  const [celkovyUploadProgress, setCelkovyUploadProgress] = useState(0);
  const [nahraneSoubory, setNahraneSoubory] = useState(0);
  const [chyboveOznameni, setChyboveOznameni] = useState('');

  const onDrop = async (prijateSubory) => {
    const platneSubory = prijateSubory.filter(soubor =>
      soubor.size <= MAX_SIZE_MB * 1024 * 1024 &&
      soubor.type.startsWith('image/')
    );

    if (platneSubory.length < prijateSubory.length) {
      setChyboveOznameni(`Některé soubory nebyly nahrány, protože nejsou obrázky nebo překračují ${MAX_SIZE_MB} MB.`);
    } else {
      setChyboveOznameni('');
    }

    const zbyvaVolnychMist = MAX_FILES - formData.screens.length;
    const souboryKNahrani = platneSubory.slice(0, zbyvaVolnychMist);

    if (souboryKNahrani.length === 0) {
      setChyboveOznameni(`Maximálně lze nahrát ${MAX_FILES} obrázků.`);
      return;
    }

    let celkovyProgres = 0;
    for (const soubor of souboryKNahrani) {
      const uploadFormData = new FormData();
      uploadFormData.append('file', soubor);

      try {
        const response = await axios.post('https://clan.varmi.cz/api/upload', uploadFormData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setCelkovyUploadProgress((prev) => prev + progress / souboryKNahrani.length);
          },
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        const nahranaPrvek = response.data[0];
        console.log("nahranaPrvek", nahranaPrvek)
        console.log("data", response.data)
        setFormData((prevFormData) => ({
          ...prevFormData,
          screens: [...prevFormData.screens, nahranaPrvek]
        }));
        setNahraneSoubory((prevFormData) => prevFormData + 1);
      } catch (chyba) {
        console.error('Nahrávání selhalo:', chyba);
        setChyboveOznameni('Nahrávání souboru selhalo.');
      }
    }

    setCelkovyUploadProgress(0);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxSize: MAX_SIZE_MB * 1024 * 1024,
  });

  const handleRegistrationComplete = () => {
    console.log("TEST")
    if (typeof onRegistrationComplete === 'function') {
      onRegistrationComplete(formData);
    }
  };

  return (
     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
      <Alert
        variant="soft"
        color="myColor"
        sx={{ borderRadius: "12px", width: "100%", mb: "25px" }}
        startDecorator={<InfoOutlinedIcon sx={{ fontSize: "3rem", color: "#b5e8ff" }} />}
      >
        <div>
          <div><strong><Typography sx={{ color: "#fff" }}>Informace</Typography></strong></div>
          <Typography level="body-sm" sx={{ color: "#b5e8ff" }}>
            Nahraj screenshot tvého inventáře, na screenu musí jít vidět i tvůj nickname. Případně můžeš přiložit screeny další výbavy či úspěchů. PS: Monitor vyfocený mobilem ti moc šance nepřidá. :))
          </Typography>
        </div>
      </Alert>
      
      {celkovyUploadProgress > 0 && celkovyUploadProgress < 100 && (
        <LinearProgress size="lg" determinate value={celkovyUploadProgress} sx={{ color: "#dd39fd", width: "100%", mb: 2 }} />
      )}
      
      <div {...getRootProps()} style={{
        border: '2px dotted #6600af',
        padding: '20px',
        cursor: 'pointer',
        backgroundColor: "#140b1c",
        width: "100%",
        height: "20vh",
        borderRadius: "8px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <input {...getInputProps()} />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
          height: '100%',
          gap: 1
        }}>
          <UploadFileIcon sx={{ 
            fontSize: "3.5rem", 
            color: "#ec8eff",
            mb: 1
          }} />
          <Typography 
            className="uploadText"
            sx={{
              color: 'white',
              fontFamily: "Prompt",
              fontSize: "1.3rem",
              mb: 1
            }}
          >
            Přetáhni sem soubory nebo <u>klikni pro nahrání!</u>
          </Typography>
          <Typography 
            className="subText"
            sx={{
              color: 'primary.main',
              fontSize: '0.9rem'
            }}
          >
            Max. {MAX_FILES} obrázků, limit {MAX_SIZE_MB} MB/obrázek
          </Typography>
        </Box>
      </div>

      {chyboveOznameni && <Alert
        variant="soft"
        color="warning"
        sx={{ borderRadius: "12px", width: "95%", mt: "15px" }}
        startDecorator={<ErrorOutlineOutlinedIcon sx={{ fontSize: "3rem", color: "#E89822" }} />}
      >
        <div>
          <div><strong><Typography sx={{ color: "#fff" }}>Upozornění</Typography></strong></div>
          <Typography level="body-sm" color="#fff8b5" sx={{ color: "#fff8b5" }}>{chyboveOznameni}</Typography>
        </div>
      </Alert>}
      {formData.screens.length > 0 && (
        <>
          <Button onClick={handleRegistrationComplete} fullWidth style={{ background: 'linear-gradient(to right, #6745ed, #DD39FD)', color: 'white', marginTop: '15px', borderRadius: "8px" }}>
            <strong>Dokončit registraci</strong>
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '15px' }}>
            <Chip size="lg" variant="solid" color="success" startDecorator={<CheckCircleOutlineRoundedIcon />}>
              <strong>Úspěšně nahraných obrázků:</strong> {nahraneSoubory}
            </Chip>
          </Box>
        </>
      )}
    </div>
  );
};

export default FileUpload;