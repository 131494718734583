import React, { useState } from 'react';
import "../Global.css";
import { Typography } from '@mui/joy';

const TruncatedNote = ({ note }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const maxLength = 125;
  const isTextTruncated = note.length > maxLength;
  const truncatedText = isTextTruncated 
    ? `${note.slice(0, maxLength)}...` 
    : note;

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Typography 
        className="TEST"
        level="body-sm" 
        fontSize="1.1rem" 
     
        sx={{
          pb: "2px",
          wordBreak: 'break-word',
          textAlign: "left",
          fontFamily: "Patrick Hand",
          lineHeight: "1.2",
          color: "#f9f7c0"
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <span style={{color: "#fffcc9"}}>{truncatedText}</span>
      </Typography>

      {showTooltip && isTextTruncated && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: '0',
          marginTop: '8px',
          padding: '10px',
          backgroundColor: '#f9f7c0',
          color: '#003366',
          borderRadius: '4px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          fontFamily: 'Patrick Hand, sans-serif',
          fontSize: '1rem',
          lineHeight: '1.2',
          fontWeight: 500,
          maxWidth: '700px',
          whiteSpace: 'pre-wrap',
          zIndex: 1000,
        }}>
          {note}
        </div>
      )}
    </div>
  );
};

export default TruncatedNote;
