import React, { useEffect, useState } from 'react';
import { AspectRatio, LinearProgress, Grid, Alert, Box, Button, Card, CardContent, Typography, Sheet, Modal, ModalDialog, Stack, FormLabel, Input, DialogTitle, DialogContent } from '@mui/joy';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SettingModal from "./SettingModal"
import RegisterForm from "./RegisterForm"
import RegisterFormResend from "./RegisterFormResend"
import RegisterFormAlt from "./RegisterFormAlt"

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginForm from "./LoginForm"
import LogoutIcon from '@mui/icons-material/Logout';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import Config from '../Config';
import LockClockRoundedIcon from '@mui/icons-material/LockClockRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./css/ClanProfile.css"


export default function ClanProfile() {
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [modalDonateData, setDonateModalDonateData] = useState({});
  const [userData, setUserData] = useState({});
  const [settingsData, setSettingsData] = useState({});
  const [totalMembers, setTotalMembers] = useState();
  const [serverMessage, setServerMessage] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isRegisterResendModalOpen, setIsRegisterResendModalOpen] = useState(false);
  const [registrationResendSuccess, setRegistrationResendSuccess] = useState(false);
  const [isRegisterAltModalOpen, setIsRegisterAltModalOpen] = useState(false);
  const [registrationAltSuccess, setRegistrationAltSuccess] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalClanPointsData, setModalClanPointsData] = useState('');
  const [altModalOpen, setIsAltModalOpen] = useState('');
  const [resendModalOpen, setIsResendModalOpen] = useState('');

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/clan/info`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUserData(data.user);
      setSettingsData(data.settings);
      setTotalMembers(data.totalMembers);
      setUserRole(data.user.role);
      console.log(data)
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const fetchClanData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/clan/basicinfo`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSettingsData(data.settings);
      setTotalMembers(data.totalMembers);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchClanData();
  }, []);

  const handleSave = async (updatedData) => {
    try {
      console.log("Omluvenka:", updatedData)
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/excuse/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data.officerInfo)
    } catch (error) {
      console.error('Error:', error);
    }
  };




  const handleAddClanPoints = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.domain}/api/points/add`, {
        method: 'POST', // Předpokládám použití metody POST, upravte podle specifikace API
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ clanPoints: modalClanPointsData })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Přidání logiky pro zpracování úspěšného odeslání dat
      const data = await response.json();
      setServerMessage(data.message); // Zobrazí zprávu serveru
      await fetchData(); // Aktualizuje data na stránce
    } catch (error) {
      console.error('There was a problem with sending the data:', error);
    }

    setIsDonateModalOpen(false); // Zavře modální okno
  };


  if (isLoading) {
    return <LinearProgress />;
  }


  function formatNumber(num) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);

    if (absNum < 1000) return sign + absNum; //Stovky
    if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce

    const formatWithOptionalDecimal = (value, unit) => {
      const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
      return sign + number + unit;
    };

    if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
    if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
    return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
  }

  const handleSettingButton = async () => {
    setIsSettingModalOpen(true)
  }

  const squareButtonStyle = {
    width: '45px',
    height: '40px',
    padding: 0,
    backgroundColor: '#1D1933',
    border: 'none',
  };

  const wrapperStyleSquare = {
    display: 'flex', // Umožní flex-item vlastnosti uvnitř wrapperu
    borderRadius: '10px',
    maxHeight: "40px",
    maxWidth: "45px",
    backgroundImage: 'linear-gradient(to right, #e82222, #ea753a)',
    padding: '2px',
    flex: 1, // Zajistí, že wrapper zabere spravedlivou část kontejneru
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row', // Uspořádá tlačítka vedle sebe
    gap: '10px', // Vytvoří mezeru mezi tlačítky
    width: '100%', // Zajistí, že kontejner zabere celou šířku rodičovského elementu
  };

  const wrapperStyle = {
    display: 'flex', // Umožní flex-item vlastnosti uvnitř wrapperu
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(to right, #6745ed, #DD39FD)',
    padding: '2px',
    flex: 1, // Zajistí, že wrapper zabere spravedlivou část kontejneru
  };

  const buttonStyleBase = {
    display: 'flex',
    justifyContent: 'center', // Centruje text/obsah tlačítka
    alignItems: 'center', // Centruje text/obsah tlačítka vertikálně
    width: '100%', // Tlačítko zabere 100% šířky svého wrapperu
    borderRadius: '7px',
    color: 'white',
    fontWeight: 600,
    padding: '7px 0px', // Vertikální padding pro zajištění výšky, šířkový padding není nutný
    flex: 1, // Tlačítko bude mít flex-grow vlastnost, aby zaplnilo prostor
  };

  const outlinedButtonStyle = {
    ...buttonStyleBase,
    backgroundColor: '#1D1933',
    border: 'none',
  };

  const buttonStyle = {
    ...buttonStyleBase,
    backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)",
    border: "1px solid #070B24",
  };

  const buttonStyleRed = {
    ...buttonStyleBase,
    backgroundImage: "linear-gradient(to right, rgba(253,57,57,1), rgba(255,109,109,1)",
    border: "1px solid #070B24",
  };

  const renderButtons = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={{ ...outlinedButtonStyle }}
                onClick={() => setIsLoginModalOpen(true)}
              >
                Přihlásit se
              </Button>
            </div>
            <Button
              endDecorator={<AssignmentIndRoundedIcon style={{ fontSize: 20 }} />}
              color="primary"
              style={{ ...buttonStyle }}
              onClick={() => setIsRegisterModalOpen(true)}
            >
              Přihláška do clanu
            </Button>
          </div>
        </>
      );
    } else if (userRole === 'pending') {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyleSquare}>
              <Button
                variant="outlined"
                color="neutral"
                style={squareButtonStyle}
                onClick={logoutUser}
              >
                <ExitToAppIcon sx={{ fontSize: "1.5rem", color: "#E95631" }} />
              </Button>
            </div>

            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={outlinedButtonStyle}
                onClick={() => handleSettingButton()}
                disabled
              >
                Nahlásit nepřítomnost
              </Button>
            </div>
            <Button
              style={{ ...buttonStyle, cursor: "not-allowed" }}
              endDecorator={<LockClockRoundedIcon style={{ fontSize: 18 }} />}
              disabled
            >
              Přihláška čeká na vyřízení
            </Button>
          </div>
        </>
      )
    } else if (userRole === 'declined') {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyleSquare}>
              <Button
                variant="outlined"
                color="neutral"
                style={squareButtonStyle}
                onClick={logoutUser}
              >
                <ExitToAppIcon sx={{ fontSize: "1.5rem", color: "#E95631" }} />
              </Button>
            </div>

            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={outlinedButtonStyle}
                onClick={() => handleSettingButton()}
                disabled
              >
                Nahlásit nepřítomnost
              </Button>
            </div>
            <Button
              style={{ ...buttonStyleRed, cursor: "not-allowed" }}
              startDecorator={<CloseRoundedIcon style={{ fontSize: 22 }} />}
              disabled
            >
              Žádost zamítnuta! :(
            </Button>
          </div>
        </>
      )
    } else if (userRole === 'ready') {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyleSquare}>
              <Button
                variant="outlined"
                color="neutral"
                style={squareButtonStyle}
                onClick={logoutUser}
              >
                <ExitToAppIcon sx={{ fontSize: "1.5rem", color: "#E95631" }} />
              </Button>
            </div>

            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={outlinedButtonStyle}
                onClick={() => handleSettingButton()}
                disabled
              >
                Nahlásit nepřítomnost
              </Button>
            </div>
            <Button
              endDecorator={<AssignmentIndRoundedIcon style={{ fontSize: 20 }} />}
              color="primary"
              style={{ ...buttonStyle }}
              onClick={() => setIsRegisterResendModalOpen(true)}
            >
              Poslat znovu přihlášku
            </Button>
          </div>
        </>
      )
    } else if (userRole === 'member' || userRole === 'officer' || userRole === 'owner' || userRole === 'dev') {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyleSquare}>
              <Button
                variant="outlined"
                color="neutral"
                style={squareButtonStyle}
                onClick={logoutUser}
              >
                <ExitToAppIcon sx={{ fontSize: "1.5rem", color: "#E95631" }} />
              </Button>
            </div>

            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={outlinedButtonStyle}
                onClick={() => handleSettingButton()}
              >
                Nahlásit nepřítomnost
              </Button>
            </div>
            <Button
              endDecorator={<AssignmentIndRoundedIcon style={{ fontSize: 20 }} />}
              color="primary"
              style={{ ...buttonStyle }}
              onClick={() => setIsRegisterAltModalOpen(true)}
            >
              Přihláška pro Alta
            </Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={containerStyle}>
            <div style={wrapperStyle}>
              <Button
                variant="outlined"
                color="neutral"
                style={{ ...outlinedButtonStyle }}
                onClick={() => setIsLoginModalOpen(true)}
              >
                Přihlásit se
              </Button>
            </div>
            <Button
              endDecorator={<AssignmentIndRoundedIcon style={{ fontSize: 20 }} />}
              color="primary"
              style={{ ...buttonStyle }}
              onClick={() => setIsRegisterModalOpen(true)}
            >
              Přihláška do clanu
            </Button>
          </div>
        </>
      )
    }
  }


  const handleRegistrationSuccess = (success) => {
    setRegistrationSuccess(success);
    setTimeout(() => setRegistrationSuccess(false), 5000);
  };

  const handleRegistrationResendSuccess = (success) => {
    setRegistrationResendSuccess(success);
    setTimeout(() => setRegistrationResendSuccess(false), 5000);
  };

  const handleRegistrationAltSuccess = (success) => {
    setRegistrationAltSuccess(success);
    setTimeout(() => setRegistrationAltSuccess(false), 5000);
  };

  const handleLoginSuccess = (success) => {
    setLoginSuccess(success);
    setTimeout(() => setLoginSuccess(false), 5000);
  };


  return (
    <Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            overflow: { xs: 'auto', sm: 'initial' },
          }}
        >
          {userData && userData.gamepasses && (
            <SettingModal
              open={isSettingModalOpen}
              onClose={() => setIsSettingModalOpen(false)}
              userData={userData}
              onSave={(updatedData) => handleSave(updatedData)}
            />
          )}
          {isRegisterModalOpen && (
            <RegisterForm
              open={isRegisterModalOpen}
              onClose={() => setIsRegisterModalOpen(false)}
              onRegistrationSuccess={handleRegistrationSuccess}
            />
          )}
          {isRegisterResendModalOpen && (
            <RegisterFormResend
              open={isRegisterResendModalOpen}
              onClose={() => setIsRegisterResendModalOpen(false)}
              onRegistrationSuccess={handleRegistrationResendSuccess}
            />
          )}
          {isRegisterAltModalOpen && (
            <RegisterFormAlt
              open={isRegisterAltModalOpen}
              onClose={() => setIsRegisterAltModalOpen(false)}
              onRegistrationSuccess={handleRegistrationAltSuccess}
            />
          )}
          {isLoginModalOpen && (
            <LoginForm
              open={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
              onLoginSuccess={handleLoginSuccess}
            />
          )}

          {serverMessage && <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>{serverMessage}</Alert>}
          {registrationSuccess && (
            <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Přihláška byla úspěšně odeslaná, nyní se můžeš přihlásit!</Alert>
          )}
          {registrationResendSuccess && (
            <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Přihláška byla úspěšně odeslaná, nyní se můžeš přihlásit!</Alert>
          )}
          {registrationAltSuccess && (
            <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Přihláška byla úspěšně odeslaná, nyní se můžeš přihlásit!</Alert>
          )}
          {loginSuccess && (
            <Alert color="success" style={{ borderRadius: "7px", width: "96%", fontWeight: 600, marginBottom: "15px" }}>Úspěšně přihlášen</Alert>
          )}
          <Card
            orientation="horizontal"
            sx={{
              width: '97%',
              flexWrap: 'wrap',
              backgroundColor: "#1d1933",
              border: "none",
              paddingRight: "20px",
              paddingTop: "30px",
              paddingBottom: "30px"
            }}
          >
            <img
              className="hideOnMobile"
              style={{ borderRadius: "8px", width: "18%", height: "18%", maxWidth: "225px", maxHeight: "225px" }}
              src="https://i.ibb.co/gDvgtVk/monkey.webp"
              srcSet="https://i.ibb.co/gDvgtVk/monkey.webp 2x"
              loading="lazy"
              alt=""
            />

            <CardContent>
              <Typography fontSize="1.8rem" fontWeight="700" fontFamily="Roboto" style={{ fontFamily: "Roboto" }}>
                [VLP] Varmiho Klubíček {settingsData.medails}
              </Typography>
              <Typography level="body-sm" fontSize="1.4rem" fontWeight="lg" textColor="text.tertiary">
                <strong>Denní poplatek:</strong> {formatNumber(settingsData.dailyFee)}
              </Typography>
              <Sheet
                sx={{
                  bgcolor: '#2a244c',
                  borderRadius: '8px',
                  p: 1.5,
                  my: 1.5,
                  display: 'flex',
                  gap: 2,
                  '& > div': { flex: 1 },
                }}
              >
                <div>
                  <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem" style={{ paddingLeft: "10px" }}>
                    Clan level
                  </Typography>
                  <Typography fontWeight="lg" style={{ paddingLeft: "10px" }}>7</Typography>
                </div>
                <div>
                  <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                    Počet členů
                  </Typography>
                  <Typography fontWeight="lg">{totalMembers}</Typography>
                </div>
                <div>
                  <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                    Clan gems
                  </Typography>
                  <Typography fontWeight="lg">{formatNumber(settingsData.clanGems)}</Typography>
                </div>
                <div>
                  <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                    Můj příspěvek
                  </Typography>
                  <Typography fontWeight="lg">{formatNumber(userData.donate)}</Typography>
                </div>
              </Sheet>
              <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
                {renderButtons()}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}