import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AlertProvider } from './components/AlertContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
     <AlertProvider>
      <App />
    </AlertProvider>
  </Router>
);
