import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import "./App.css";
import "./Global.css";
import { AlertProvider } from './components/AlertContext';
import Dashboard from "./components/Dashboard"
import GrafDashboard from "./components/GrafDashboard"; // Impo
import OAuthRedirect from "./components/Close"

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: { solidBg: '#6745ed', softBg: '#DD39FD' },
        myColor: { softBg: "#0A2744" },
      },
    },
    dark: {
      palette: {
        customColor: {
          outlinedBorder: '#DD39FD',
          outlinedHoverBg: '#DD39FD',
          outlinedHoverBorder: '#DD39FD',
          outlinedActiveBg: '#DD39FD',
          outlinedDisabledBorder: '#DD39FD',
        },
        primary: { solidBg: '#6745ed', softBg: '#DD39FD' },
        myColor: { softBg: "#0A2744" },
      },
    },
  },
});

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const discordId = urlParams.get('discord_id');
    const discordUsername = urlParams.get('discord_username');
    const isMember = urlParams.get('isMember');
    const isBooster = urlParams.get('isBooster');
    const isSub = urlParams.get('isSub');
    const tokenExpire = urlParams.get('tokenExpire');
    const disToken = urlParams.get('token');
    const refreshToken = urlParams.get('refreshToken');

    if (discordId || discordUsername || isMember || isBooster || isSub || tokenExpire || disToken || refreshToken) {
      if (discordId) localStorage.setItem('discord_id', discordId);
      if (discordUsername) localStorage.setItem('discord_username', discordUsername);
      if (isMember) localStorage.setItem('isMember', isMember);
      if (isBooster) localStorage.setItem('isBooster', isBooster);
      if (isSub) localStorage.setItem('isSub', isSub);
      if (tokenExpire) localStorage.setItem('tokenExpire', tokenExpire);
      if (disToken) localStorage.setItem('disToken', disToken);
      if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

      navigate(location.pathname, { replace: true });
    }
  }, [navigate, location]);

  return (
    <AlertProvider>
      <CssVarsProvider defaultMode="dark" theme={theme} colorSchemeSelector="#demo_dark-mode-by-default" modeStorageKey="demo_dark-mode-by-default" disableNestedContext>
        <div className="App">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/rap/:itemName" element={<GrafDashboard />} />
            <Route path="/OAuth" element={<OAuthRedirect />} />
          </Routes>
        </div>
      </CssVarsProvider>
    </AlertProvider>
  );
}

export default App;

