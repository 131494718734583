import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Button, LinearProgress, Typography, Snackbar } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./css/dashboard.css"
import Config from '../Config';


const ResponsiveDiv = styled('div')(({ theme }) => ({
    margin: "0 auto",
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '1%',
        marginRight: '1%',
    },
    [theme.breakpoints.down('xs')]: {
        marginLeft: '0%',
        marginRight: '0%',
    }
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2rem',
    padding: '0 1rem',
    '& .dashboard-content-border': {
        width: '600px !important',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        }
    }
}));

const LogoHeader = styled('header')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
        width: '270px',
        margin: '16px 0',
        [theme.breakpoints.down('sm')]: {
            position: 'static !important',
            transform: 'none !important',
            left: 'auto !important',
        }
    }
}));

const OAuthRedirect = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        window.close();
      }, 2000);
    }, []);
    
    const handleClose = () => {
        try {
            if (window.opener && !window.opener.closed) {
                window.close();
            } else {
                window.open('', '_self').close();
            }
        } catch (e) {
            try {
                window.top.close();
            } catch (e2) {
                setShowAlert(true);
            }
        }
    };

    return (
        <ResponsiveDiv>
            <div className="dashboard">
                <div className="dashboard-background"></div>
                <LogoHeader className="dashboard-header">
                    <img 
                        src="https://i.ibb.co/WKqnnL8/varmilogopng.png" 
                        alt="Logo" 
                        className="dashboard-logo"
                    />
                </LogoHeader>

                <ContentWrapper>
                    <div className="dashboard-content-border">
                        <div className="dashboard-content" style={{ padding: '2rem', textAlign: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}>
                                <CheckCircleIcon 
                                    sx={{ 
                                        fontSize: 64, 
                                        color: '#DB39FD',
                                        backgroundColor: '#DB39FD30',
                                        borderRadius: '50%',
                                        padding: '8px'
                                    }} 
                                />
                                <Typography level="h2" sx={{ color: 'white' }}>
                                    Přihlášení úspěšné!
                                </Typography>
                                <Typography sx={{ color: 'gray.300' }}>
                                    Byli jste úspěšně přihlášeni. Nyní můžete toto okno zavřít a pokračovat v registraci.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>

                <Snackbar
                    variant="solid"
                    color="neutral"
                    autoHideDuration={3000}
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    Prosím, zavřete toto okno manuálně pomocí křížku v pravém horním rohu.
                </Snackbar>
            </div>
        </ResponsiveDiv>
    );
};

export default OAuthRedirect;